import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '@/stores';
import apiStore from './api.store';

export const useComposerWorkExperienceStore = defineStore({
    id: 'composerWorkExperience',
    state: () => ({
        composerWorkExperiences: {},
        composerWorkExperience: {},
        totalData: 0,
        totalAllData: 0,
    }),
    actions: {
        async getWorkExperiences(id) {
            this.composerWorkExperiences = { loading: true };
            this.composerWorkExperience = {};
            this.totalData = 0;
            this.totalAllData = 0;
            const newUrl = apiStore.composer.composerWorkExperiences.replace(":composerId", `${id}`);
            const options = {
                url: newUrl,
                params: {
                    page:1,
                    limit:20,
                }
            };
            try {
                const getComposersResult = await fetchWrapper.get(options);   
                this.composerWorkExperiences = getComposersResult.data;   
            } catch (error) {
                this.composerWorkExperiences = { error };
            }
        },
        async getWorkExperience(id, experienceId) {
            this.composerWorkExperience = { loading: true };
            let newUrl = apiStore.composer.composerWorkExperience.replace(":composerId", `${id}`).replace(":workExperienceId", `${experienceId}`);
            newUrl.replace(`:workExperienceId`, experienceId);
            const options = {
                url: newUrl,
            };
            try {
                const getComposerEducationResult = await fetchWrapper.get(options);    
                this.composerWorkExperience = getComposerEducationResult.data;
            } catch (error) {
                this.composerWorkExperience = { error };
            }
        },
        async createComposerWorkExperience(payload, id) {
            const alertStore = useAlertStore();
            let newUrl = apiStore.composer.composerWorkExperienceCreate.replace(":composerId", `${id}`);
            payload.startYear = parseInt(payload.startYear);
            payload.endYear = parseInt(payload.endYear);
            const options = {
                url: newUrl,
                body: payload,
            };
            try {
                await fetchWrapper.post(options);
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async updateComposerWorkExperience(payload, id, experienceId) {
            const alertStore = useAlertStore();
            let newUrl = apiStore.composer.composerWorkExperiencePatch.replace(":composerId", `${id}`).replace(`:workExperienceId`, experienceId);
              payload.startYear = parseInt(payload.startYear);
            payload.endYear = parseInt(payload.endYear);
            const options = {
                url: newUrl,
                body: payload,
            };
            try {
                await fetchWrapper.patch(options); 
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async delete(id, experienceId) {
            const index = this.composerWorkExperiences.findIndex(x => x.id === experienceId);
            if (index > -1) this.composerWorkExperiences[index].isDeleting = true;
            const alertStore = useAlertStore();
            let newUrl = apiStore.composer.composerWorkExperienceDelete.replace(":composerId", `${id}`).replace(":workExperienceId", `${experienceId}`);
            const options = {
                url: newUrl,
            };
            try {
                await fetchWrapper.delete(options);
                this.composerWorkExperiences = this.composerWorkExperiences.filter(x => x.id !== experienceId);
                alertStore.success('Data berhasil dihapus');  
            } catch (error) {
                this.composerWorkExperiences[index].isDeleting = false;
                console.log(`error`, error);
                alertStore.error(error);
            }
        },
    }
});
