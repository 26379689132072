import {
    defineStore
  } from 'pinia';
  import {
    fetchWrapper
  } from '@/helpers';
  import apiStore from './api.store';
  
  export const useMarriageStatusStore = defineStore({
    id: 'marriageStatus',
    state: () => ({
      marriageStatuses: {},
      selectedMarriageStatus: null,
    }),
    actions: {
      async getMarriageStatuses() {
        try {
          this.selectedMarriageStatus = null;
          this.marriageStatuses = { loading: true };
          const options = {
            url: apiStore.statusAttribute.marriageStatuses,
          };
          const getMariageStatussResult = await fetchWrapper.get(options);
          this.marriageStatuses = getMariageStatussResult.data;
        } catch (error) {
          this.marriageStatuses = { error };
        }
      }
    }
  });