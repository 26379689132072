import permission  from "@/stores/permission.store";

export default {
  path: '/regulasi',
  component: () => import("@/views/regulation-blogs/Layout.vue"),
  children: [
      { path: '', 
        meta: {
          iconActive: 'Regulasi',
          permission: permission.regulationBlog.read,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Regulasi',
                }
            ]
        },
        component: () => import("@/views/regulation-blogs/List.vue") 
      },
      { path: 'add', 
        meta: {
          iconActive: 'Regulasi',
          permission: permission.regulationBlog.create,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Regulasi',
                    link: '/regulasi',
                },
                {
                  name: 'Tambah Regulasi',
                },
            ]
        },
        component: () => import("@/views/regulation-blogs/Form.vue") 
      },
      { path: 'edit/:id', 
        meta: {
          iconActive: 'Regulasi',
          permission: permission.regulationBlog.update,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Regulasi',
                    link: '/regulasi'
                },
                {
                  name: 'Ubah Regulasi',
              },
            ]
        },
        component: () => import("@/views/regulation-blogs/Form.vue") 
      },
  ]
};
