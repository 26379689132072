import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore, useRegionStore, useAuthStore } from '@/stores';
import apiStore from './api.store';

export const useBlogStore = defineStore({
    id: 'blog',
    state: () => ({
        blogs: {},
        blog: {},
        totalData: 0,
        totalAllData: 0,
        blogProblems: {}
    }),
    actions: {
        async getBlogs(search = null, page = 1, limit = 10, order = ['createdAt', 'desc']) {
            this.blogs = { loading: true };
            this.blog = {};
            this.totalData = 0;
            this.totalAllData = 0;
            const options = {
                url: apiStore.blog.blogs,
                params: {
                    order: order[0],
                    sort: order[1],
                    search: search || null,
                    page,
                    limit,
                }
            };
            try {
                const getBlogsResult = await fetchWrapper.get(options);   
                this.totalData = getBlogsResult.totalData;
                this.totalAllData = getBlogsResult.totalAllData;
                this.blogs = getBlogsResult.data;   
            } catch (error) {
                this.blogs = { error };
            }
        },
        async getBlog(id) {
            this.blog = { loading: true };
            const region  = useRegionStore();
            const newUrl = apiStore.blog.blog.replace(":blogId", `${id}`);
            const options = {
                url: newUrl,
            };
            try {
                const getBlogResult = await fetchWrapper.get(options);    
                if (getBlogResult.data.province) {
                    region.selectedProvince = getBlogResult.data.province.code;
                    await region.filterRegencies(region.selectedProvince);
                }
                if (getBlogResult.data.regency) {
                    region.selectedRegency = getBlogResult.data.regency.code;
                    let provinceCode = getBlogResult.data.regency.code.split(".");
                    if (region.selectedProvince == null) await region.filterRegencies(provinceCode[0]);
                } 
                this.blog = getBlogResult.data;
            } catch (error) {
                this.blog = { error };
            }
        },
        async createBlog(payload) {
            const alertStore = useAlertStore();
            const options = {
                url: apiStore.blog.blogCreate,
                body: payload,
                isMultipart: true,
            };
            try {
                await fetchWrapper.post(options);
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async updateBlog(id, payload) {
            const authStore  = useAuthStore();
            const alertStore = useAlertStore();
            const newUrl = apiStore.blog.blogPatch.replace(":blogId", `${id}`);
            if (payload.featuredImage instanceof File === false) delete payload.featuredImage;
            if (authStore.user.regencyCode) payload.regencyCode = authStore.user.regencyCode;
            if (authStore.user.provinceCode) payload.provinceCode = authStore.user.provinceCode;
            const options = {
                url: newUrl,
                body: payload,
                isMultipart: true,
            };
            try {
                await fetchWrapper.patch(options); 
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async deleteBlog(id, search=null, page=null, limit=null, order = ['createdAt', 'desc']) {
            const index = this.blogs.findIndex(x => x.id === id);
            if (index > -1) this.blogs[index].isDeleting = true;
            const alertStore = useAlertStore();
            const newUrl = apiStore.blog.blogDelete.replace(":blogId", `${id}`);
            const options = {
                url: newUrl,
            };
            try {
                await fetchWrapper.delete(options);
                await this.getBlogs(search, page, limit, order);
                alertStore.success('Data berhasil dihapus');  
            } catch (error) {
                console.log(`error`, error);
                this.documents[index].isDeleting = false;
                alertStore.error(error);
            }
        },

        async getBlogProblems (search, code) {
            this.blogProblems = { loading: true };
            const newUrl = apiStore.blog.problem
            const options = {
                url: newUrl,
                params: {
                    ...code,
                    search
                }
            };
            try {
                const getProblemResult = await fetchWrapper.get(options);
                this.blogProblems = getProblemResult.data;
                this.blogProblems.totalAllData = getProblemResult.totalAllData;
                this.blogProblems.totalData = getProblemResult.totalData;
            } catch (error) {
                this.blogProblems = { error };
            }
        },

        async findTags (search) {
            const alertStore = useAlertStore();
            const newUrl = apiStore.blog.tags
            const options = {
                url: newUrl,
                params: {
                    search
                }
            };
            try {
                const tags = await fetchWrapper.get(options);
                return tags.data;
            } catch (error) {
                alertStore.error(error);
            }
        },
    }
});
