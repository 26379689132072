import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '@/stores';
import { useRegionStore, useAuthStore } from '@/stores';
import apiStore from './api.store';

export const useInstitutionStore = defineStore({
    id: 'institution',
    state: () => ({
        institutionCategories: {},
        institutions: {},
        institution: {},
        selectedCategory: {},
        totalData: 0,
        totalAllData: 0,
    }),
    actions: {
        async getInstitutionCategories() {
            this.institutionCategories = { loading: true };
            this.selectedCategory = {};
            this.institution = {};
            const options = {
                url: apiStore.institution.institutionCategories,
                params: {
                    page: 1,
                    limit: 30,
                }
            };
            try {
                const getinstitutionCategoriesResult = await fetchWrapper.get(options);  
                this.institutionCategories = getinstitutionCategoriesResult.data;
                this.institutions = {};
            } catch (error) {
                this.institutionCategories = { error };
            }
        },

        filterInstitutionCategories () {
            try {
                const findInstitutionCategories = this.institutionCategories.filter(v => v.type === this.selectedCategory);
                this.categories = findInstitutionCategories;
            } catch (error) {
                
            }
        },
        async getInstitutions(search = null, page = 1, limit = 10, order = ['createdAt', 'desc'], option = []) {
            this.institutions = { loading: true };
            this.totalData = 0;
            this.totalAllData = 0;
            const options = {
                url: apiStore.institution.institutions,
                params: {
                    order: order[0],
                    sort: order[1],
                    search: search || null,
                    page,
                    limit,
                }
            };
            option.forEach((v)=> {
                if(v.regencyCode) options.params.regencyCode = v.regencyCode
                if(v.provinceCode) options.params.provinceCode = v.provinceCode
            });
            try {
                const getInstitutionsResult = await fetchWrapper.get(options);   
                this.totalData = getInstitutionsResult.totalData;
                this.totalAllData = getInstitutionsResult.totalAllData;
                this.institutions = getInstitutionsResult.data;   
            } catch (error) {
                this.institutions = { error };
            }
        },
        async getInstitution(id) {
            const region  = useRegionStore();
            this.institution = { loading: true };
            const newUrl = apiStore.institution.institution.replace(":institutionId", `${id}`);
            const options = {
                url: newUrl,
            };
            try {
                const getInstitutionResult = await fetchWrapper.get(options);    
                this.institution = getInstitutionResult.data;
                this.selectedCategory = getInstitutionResult.data.category.id;
                if (getInstitutionResult.data.province) {
                    region.selectedProvince = getInstitutionResult.data.province.code;
                    await region.filterRegencies(region.selectedProvince);
                }
                if (getInstitutionResult.data.regency) {
                    region.selectedRegency = getInstitutionResult.data.regency.code;
                    let provinceCode = getInstitutionResult.data.regency.code.split(".");
                    if (region.selectedProvince == null) await region.filterRegencies(provinceCode[0]);
                }
            } catch (error) {
                this.institution = { error };
            }
        },
        async createInstitution(payload) {
            payload.positions.forEach((v, i) => {
                if(v.name == '' || !v.name) payload.positions.splice(i, 1);
            });
            const alertStore = useAlertStore();
            const options = {
                url: apiStore.institution.institutionCreate,
                body: payload
            };
            try {
                await fetchWrapper.post(options);
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async updateInstitution(id, payload) {
            const authStore = useAuthStore();
            if (authStore.user.regencyCode) payload.regencyCode = authStore.user.regencyCode;
            if (authStore.user.provinceCode) payload.provinceCode = authStore.user.provinceCode;
            payload.positions.forEach((v, i) => {
                if(v.name == '' || !v.name) payload.positions.splice(i, 1);
                // payload.positions[i].positionId = v.id;
            });
            payload.positions.forEach((w, j) => {
                payload.positions[j].positionId = w.id;
            });
            const alertStore = useAlertStore();
            const newUrl = apiStore.institution.institutionPatch.replace(":institutionId", `${id}`);
            const options = {
                url: newUrl,
                body: payload
            };
            try {
                await fetchWrapper.patch(options); 
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async deleteInstitution(id, search=null, page=null, limit=null, order = ['createdAt', 'desc']) {
            const index = this.institutions.findIndex(x => x.id === id);
            if (index > -1) this.institutions[index].isDeleting = true;
            const alertStore = useAlertStore();
            const newUrl = apiStore.institution.institutionDelete.replace(":institutionId", `${id}`);
            const options = {
                url: newUrl,
            };
            try {
                await fetchWrapper.delete(options);
                await this.getInstitutions(search, page, null, limit, order);
                alertStore.success('Data berhasil dihapus');  
            } catch (error) {
                this.institutions[index].isDeleting = false;
                console.log(`error`, error);
                alertStore.error(error);
            }
        },
    }
});
