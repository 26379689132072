import { createRouter, createWebHistory } from "vue-router";
import documentRoutes from "./document.routes";
import dapobudRoutes from "./dapobud.routes";
import nomenKlaturRoutes from "./institution.routes";
import educationRoutes from "./education.routes";
import regulationRoutes from "./regulation.routes";
import composerRoutes from "./composer.routes";
import composerHistoryRoutes from "./composer-history.routes";
import userRoutes from "./user.routes";
import roleRoutes from "./role.routes";
import authRoutes from "./auth.routes";
import blogRoutes from "./blog.routes";
import regulationBlogRoutes from "./regulation-blog.routes";
import { useAuthStore, useAlertStore } from "@/stores";
import problemRoutes from "./problem.routes";

export const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      component: () => import("@/views/Home.vue"),
      meta: {
        iconActive: "dashboard",
        breadcrumb: [
          {
            name: "Dashboard",
          },
        ],
      },
    },
    { ...authRoutes },
    { ...documentRoutes },
    { ...dapobudRoutes },
    { ...nomenKlaturRoutes },
    { ...educationRoutes },
    { ...regulationRoutes },
    { ...composerRoutes },
    { ...composerHistoryRoutes },
    { ...userRoutes },
    { ...roleRoutes },
    { ...blogRoutes },
    { ...regulationBlogRoutes },
    { ...problemRoutes },
    // catch all redirect to home page
    { path: "/:pathMatch(.*)*", redirect: "/" },
  ],
});

router.beforeEach(async (to) => {
  // clear alert on route change
  // const alertStore = useAlertStore();
  // alertStore.clear();

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/auth/login"];
  const authRequired = !publicPages.includes(to.path);
  const authStore = useAuthStore();
  const checkToken = await window.$cookies.get("ppkd-at");
  const titleMeta =
    to.meta && to.meta.breadcrumb ? to.meta.breadcrumb : "PPKD 2022";
  document.title =
    titleMeta.length > 0 ? titleMeta[titleMeta.length - 1].name : titleMeta;
  if (publicPages) {
    document.title = "PPKD 2022";
  }

  if (to && to.params && to.params && to.params.slug === "bab-8-lampiran")
    return `/dokumen/${to.params.id}/content/bab-8.1-surat-keputusan-kepala-daerah-tim-penyusun-ppkd/add`;
  if (
    to &&
    to.params &&
    to.params &&
    to.params.slug === "bab-7-permasalahan-dan-rekomendasi"
  )
    return `/dokumen/${to.params.id}/content/bab-7.1-permasalahan-dan-rekomendasi/add`;
  if (
    to.path !== "/" &&
    authStore.permissions &&
    !authStore.permissions.includes(to.meta.permission)
  )
    return "/";
  if (
    (authRequired && !checkToken) ||
    (authRequired && !authStore.permissions)
  ) {
    authStore.returnUrl = to.fullPath;
    authStore.user = null;
    authStore.permissions = null;
    localStorage.clear();
    const refreshToken = await authStore.checkAuth();
    console.log(`routerRefreshToken`, refreshToken ? true : false);
    if (refreshToken) return authStore.returnUrl;
    return "/auth/login";
  }
});
