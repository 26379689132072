import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore, useEducationInstitutionStore, useDocumentStore } from '@/stores';
import apiStore from './api.store';
import {
    router
} from '@/router';

export const useDocumentEducationContentStore = defineStore({
    id: 'documentEducationContent',
    state: () => ({
        documentEducations: {},
        documentEducation: {},
        educations: {},
        totalData: 0,
        totalAllData: 0,
        selectedEducation: {}
    }),
    actions: {
        async getEducations() {
            const educationInstitutions = useEducationInstitutionStore();
            const document = useDocumentStore();
            this.educations = { loading: true };
            let option = [];
            try {
                let provinceCode = document.document.province?.code || null;
                let regencyCode = document.document.regency?.code || null;
                if (provinceCode) option.push({provinceCode: provinceCode});
                if (regencyCode) option.push({regencyCode: regencyCode});
                await educationInstitutions.getEducationInstitutions(null, 1, 100, [], option);
                this.educations = educationInstitutions.educationInstitutions;
            } catch (error) {
                console.log(error);
                this.educations = { error };
            }
        },
        async getDocumentEducations(id, search = null, page = 1, limit = 10, order = ['createdAt', 'desc']) {
            this.documentEducations = { loading: true };
            this.documentEducation = {};
            this.totalData = 0;
            this.totalAllData = 0;
            const options = {
                url: apiStore.documentEducation.documentEducations.replace(":ppkdDocumentId", `${id}`),
                params: {
                    order: order[0],
                    sort: order[1],
                    search: search || null,
                    page,
                    limit,
                }
            };
            options.params.type = 'Perguruan Tinggi';
            const slug = router.currentRoute.value.params.slug;
         
            if (slug === 'bab-3.1-lembaga-pendidikan-menengah-bidang-kebudayaan') options.params.type = 'Sekolah';
            try {
                const getDocumentEducationResult = await fetchWrapper.get(options);   
                this.totalData = getDocumentEducationResult.totalData;
                this.totalAllData = getDocumentEducationResult.totalAllData;
                this.documentEducations = getDocumentEducationResult.data;   
            } catch (error) {
                this.documentEducations = { error };
            }
        },
        async getDocumentEducation(id, educationId) {
            this.documentEducation = { loading: true };
            const newUrl = apiStore.documentEducation.documentEducation.replace(":ppkdDocumentId", `${id}`).replace(":ppkdDocumentEducationId", `${educationId}`);
            const options = {
                url: newUrl,
            };
            try {
                const getDocumentEducationResult = await fetchWrapper.get(options);   
                this.documentEducation = getDocumentEducationResult.data;
            } catch (error) {
                this.documentEducation = { error };
            }
        },
        async createDocumentComposer(payload, id) {
            const alertStore = useAlertStore();
            const options = {
                url: apiStore.documentEducation.documentEducationCreate.replace(":ppkdDocumentId", `${id}`),
                body: payload,
                isMultipart: false,
            };
            try {
                await fetchWrapper.post(options);
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async updateDocumentComposer(id, educationId, payload) {
            const alertStore = useAlertStore();
            const newUrl = apiStore.documentEducation.documentEducationPatch.replace(":ppkdDocumentId", `${id}`).replace(":ppkdDocumentEducationId", `${educationId}`);
            const options = {
                url: newUrl,
                body: payload,
                isMultipart: false,
            };
            try {
                await fetchWrapper.patch(options); 
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async deleteDocumentEducation(id, educationId, search=null, page=null, limit=null, order = ['createdAt', 'desc']) {
            const index = this.documentEducations.findIndex(x => x.id === id);
            if (index > -1) this.documentEducations[index].isDeleting = true;
            const alertStore = useAlertStore();
            const newUrl = apiStore.documentEducation.documentEducationDelete.replace(":ppkdDocumentId", `${id}`).replace(":ppkdDocumentEducationId", `${educationId}`);;
            const options = {
                url: newUrl,
            };
            try {
                await fetchWrapper.delete(options);
                await this.getDocumentEducations(id, search, page, limit, order);
                alertStore.success('Data berhasil dihapus');  
            } catch (error) {
                console.log(`error`, error);
                this.documentEducations[index].isDeleting = false;
                alertStore.error(error);
            }
        },
        
    }
});
