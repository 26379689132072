import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '@/stores';
import apiStore from './api.store';
import {
    router
} from '@/router';

export const useDocumentForumContentStore = defineStore({
    id: 'documentForumContent',
    state: () => ({
        documentForums: {},
        documentForum: {},
        documentForumPhotos: {},
        documentForumPhoto: {},
        educations: {},
        totalData: 0,
        totalAllData: 0,
    }),
    actions: {
        async getDocumentForums(id, search = null, page = 1, limit = 10, order = ['createdAt', 'desc']) {
            this.documentForums = { loading: true };
            this.documentForum = {};
            this.totalData = 0;
            this.totalAllData = 0;
            const options = {
                url: apiStore.documentForum.documentForums.replace(":ppkdDocumentId", `${id}`),
                params: {
                    order: order[0],
                    sort: order[1],
                    search: search || null,
                    page,
                    limit,
                }
            };
            options.params.type = 'Forum Diskusi Terpumpun';
            const slug = router.currentRoute.value.params.slug;
            const terpumpun = ['bab-8.6-dokumentasi-foto-forum-diskusi-terpumpun', 'bab-8.4-notulensi-forum-diskusi-terpumpun']
         
            if (terpumpun.includes(slug) === false) options.params.type = 'Forum Terbuka';
            try {
                const getDocumentForumResult = await fetchWrapper.get(options);   
                this.totalData = getDocumentForumResult.totalData;
                this.totalAllData = getDocumentForumResult.totalAllData;
                this.documentForums = getDocumentForumResult.data;   
            } catch (error) {
                this.documentForums = { error };
            }
        },
        async getDocumentForum(id, forumId) {
            this.documentForum = { loading: true };
            const newUrl = apiStore.documentForum.documentForum.replace(":ppkdDocumentId", `${id}`).replace(":ppkdDocumentForumId", `${forumId}`);
            const options = {
                url: newUrl,
            };
            try {
                const getDocumentForumResult = await fetchWrapper.get(options);   
                this.documentForum = getDocumentForumResult.data;
                this.documentForum.file = getDocumentForumResult.data.note?.file || null;
            } catch (error) {
                this.documentForum = { error };
            }
        },
        async createDocumentForum(payload, id) {
            
            const alertStore = useAlertStore();
            if (payload.file instanceof File === true)  {
                payload.file = payload.file;
            } else {
                delete payload.file;
            }
            const options = {
                url: apiStore.documentForum.documentForumCreate.replace(":ppkdDocumentId", `${id}`),
                body: payload,
                isMultipart: true,
            };

            payload.type = 'Forum Diskusi Terpumpun';
            const slug = router.currentRoute.value.params.slug;
         
            if (slug === 'bab-8.3-notulensi-forum-terbuka') payload.type = 'Forum Terbuka';
            try {
                await fetchWrapper.post(options);
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async updateDocumentForum(id, forumId, payload) {
            const alertStore = useAlertStore();
            if (payload.file instanceof File === true)  {
                payload.file = payload.file;
            } else {
                delete payload.file;
            }
            const newUrl = apiStore.documentForum.documentForumPatch.replace(":ppkdDocumentId", `${id}`).replace(":ppkdDocumentForumId", `${forumId}`);
            const options = {
                url: newUrl,
                body: payload,
                isMultipart: true,
            };
            payload.type = 'Forum Diskusi Terpumpun';
            const slug = router.currentRoute.value.params.slug;
         
            if (slug === 'bab-8.3-notulensi-forum-terbuka') payload.type = 'Forum Terbuka';
            try {
                await fetchWrapper.patch(options); 
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async getDocumentForumPhotos(id, forumId) {
            this.documentForumPhotos = { loading: true };
            const options = {
                url: apiStore.documentForum.documentForumPhotos.replace(":ppkdDocumentId", `${id}`).replace(":ppkdDocumentForumId", `${forumId}`),
                params: {
                    order: 'createdAt',
                    sort: 'desc',
                    page: 1,
                    limit: 100
                }
            };
            try {
                const getDocumentForumPhotosResult = await fetchWrapper.get(options);   
                this.documentForumPhotos = getDocumentForumPhotosResult.data;   
            } catch (error) {
                this.documentForumPhotos = { error };
            }
        },
        async createDocumentForumPhoto(payload, id, forumId) {
            console.log(forumId);
            const alertStore = useAlertStore();
            if (payload.file instanceof File === true)  {
                payload.photo = payload.file;
            } else {
                delete payload.file;
            }
            const options = {
                url: apiStore.documentForum.documentForumPhotoCreate.replace(":ppkdDocumentId", `${id}`).replace(":ppkdDocumentForumId", `${forumId}`),
                body: payload,
                isMultipart: true,
            };
            try {
                await fetchWrapper.post(options);
                await this.getDocumentForumPhotos(id, forumId);
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async updateDocumentForumPhoto(id, forumId, photoId, payload) {
            const alertStore = useAlertStore();
            if (payload.file instanceof File === true)  {
                payload.photo = payload.file;
            } else {
                delete payload.file;
            }
            const newUrl = apiStore.documentForum.documentForumPhotoPatch.replace(":ppkdDocumentId", `${id}`).replace(":ppkdDocumentForumId", `${forumId}`).replace(":photoId", `${photoId}`);
            const options = {
                url: newUrl,
                body: payload,
                isMultipart: true,
            };
            try {
                await fetchWrapper.patch(options); 
                await this.getDocumentForumPhotos(id, forumId);
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async deleteDocumentForum(id, forumId, search=null, page=null, limit=null, order = ['createdAt', 'desc']) {
            const index = this.documentForums.findIndex(x => x.id === id);
            if (index > -1) this.documentForums[index].isDeleting = true;
            const alertStore = useAlertStore();
            const newUrl = apiStore.documentForum.documentForumDelete.replace(":ppkdDocumentId", `${id}`).replace(":ppkdDocumentForumId", `${forumId}`);;
            const options = {
                url: newUrl,
            };
            try {
                await fetchWrapper.delete(options);
                await this.getDocumentForums(id, search, page, limit, order);
                alertStore.success('Data berhasil dihapus');  
            } catch (error) {
                console.log(`error`, error);
                this.documentForums[index].isDeleting = false;
                alertStore.error(error);
            }
        },

        async deleteDocumentForumPhoto(id, forumId, photoId) {
            const alertStore = useAlertStore();
            const newUrl = apiStore.documentForum.documentForumPhotoDelete.replace(":ppkdDocumentId", `${id}`).replace(":ppkdDocumentForumId", `${forumId}`).replace(":photoId", `${photoId}`);
            const options = {
                url: newUrl,
            };
            try {
                await fetchWrapper.delete(options);
                await this.getDocumentForumPhotos(id, forumId);
                alertStore.success('Data berhasil dihapus');  
            } catch (error) {
                console.log(`error`, error);
                await this.getDocumentForumPhotos(id, forumId);
                alertStore.error(error);
            }
        },
        
    }
});
