import permission  from "@/stores/permission.store";

export default {
  path: '/roles',
  component: () => import("@/views/roles/Layout.vue"),
  children: [
      { path: '', 
        meta: {
          iconActive: 'Pengguna',
          permission: permission.role.read,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Hak Akses',
                }
            ]
        },
        component: () => import("@/views/roles/List.vue") 
      },
      { path: 'add', 
        meta: {
          iconActive: 'Pengguna',
          permission: permission.role.create,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Hak Akses',
                    link: '/roles',
                },
                {
                  name: 'Tambah Hak Akses',
                },
            ]
        },
        component: () => import("@/views/roles/Form.vue") 
      },
      { path: 'edit/:id', 
        meta: {
          iconActive: 'Pengguna',
          permission: permission.role.update,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Hak Akses',
                    link: '/roles'
                },
                {
                  name: 'Ubah Hak Akses',
              },
            ]
        },
        component: () => import("@/views/roles/Form.vue") 
      },
  ]
};
