import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '@/stores';
import apiStore from './api.store';

export const useComposerEducationExperienceStore = defineStore({
    id: 'composerEducationExperience',
    state: () => ({
        composerEducationExperiences: {},
        composerEducationExperience: {},
        totalData: 0,
        totalAllData: 0,
    }),
    actions: {
        async getEducationExperiences(id) {
            this.composerEducationExperiences = { loading: true };
            this.composerEducationExperience = {};
            this.totalData = 0;
            this.totalAllData = 0;
            const newUrl = apiStore.composer.composerEducationExperiences.replace(":composerId", `${id}`);
            const options = {
                url: newUrl,
                params: {
                    page:1,
                    limit:20,
                }
            };
            try {
                const getComposersResult = await fetchWrapper.get(options);   
                this.composerEducationExperiences = getComposersResult.data;   
            } catch (error) {
                this.composerEducationExperiences = { error };
            }
        },
        async getEducationExperience(id, educationId) {
            this.composerEducationExperience = { loading: true };
            let newUrl = apiStore.composer.composerEducationExperience.replace(":composerId", `${id}`).replace(":educationId", `${educationId}`);
            const options = {
                url: newUrl,
            };
            try {
                const getComposerEducationResult = await fetchWrapper.get(options);    
                this.composerEducationExperience = getComposerEducationResult.data;
            } catch (error) {
                this.composerEducationExperience = { error };
            }
        },
        async createComposerEducationExperience(payload, id) {
            const alertStore = useAlertStore();
            let newUrl = apiStore.composer.composerEducationExperienceCreate.replace(":composerId", `${id}`);
            payload.graduationYear = parseInt(payload.graduationYear);
           
            const options = {
                url: newUrl,
                body: payload,
            };
            try {
                await fetchWrapper.post(options);
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async updateComposerEducationExperience(payload, id, educationId) {
            const alertStore = useAlertStore();
            let newUrl = apiStore.composer.composerEducationExperiencePatch.replace(":composerId", `${id}`).replace(`:educationId`, educationId);
            payload.graduationYear = parseInt(payload.graduationYear);
           
            const options = {
                url: newUrl,
                body: payload,
            };
            try {
                await fetchWrapper.patch(options); 
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async delete(id, educationId) {
            const index = this.composerEducationExperiences.findIndex(x => x.id === educationId);
            if (index > -1) this.composerEducationExperiences[index].isDeleting = true;
            const alertStore = useAlertStore();
            let newUrl = apiStore.composer.composerEducationExperienceDelete.replace(":composerId", `${id}`).replace(":educationId", `${educationId}`);
            const options = {
                url: newUrl,
            };
            try {
                await fetchWrapper.delete(options);
                this.composerEducationExperiences = this.composerEducationExperiences.filter(x => x.id !== educationId);
                alertStore.success('Data berhasil dihapus');  
            } catch (error) {
                this.composerEducationExperiences[index].isDeleting = false;
                console.log(`error`, error);
                alertStore.error(error);
            }
        },
    }
});
