<script setup>
import { storeToRefs } from 'pinia';
import { useAlertStore } from '@/stores';

const alertStore = useAlertStore();
const { alert } = storeToRefs(alertStore);
</script>

<template>
    <div v-if="alert">
        <div class="row alert-position">
            <div class="col">
                <div class="alert alert-dismissable fade show" :class="alert.type" role="alert">
                {{ alert.message }}
                <!-- <button @click="alertStore.clear()" class="btn btn-close btn-sm" data-bs-dismiss="alert"
                    aria-label="Close"></button> -->
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

svg,
img {
    width: 20px;
    height: 20px;
    fill: #842029;
}
.alert-position {
    display: block;
    position: fixed;
    right: 0;
    width: 425px;
    text-align: left;
    top:10px;
    margin-right: 10px;
    animation: fadeIn 0.2s;
    z-index:9999;
}
</style>