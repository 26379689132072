import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '@/stores';
import { useRegionStore, useAuthStore } from '@/stores';
import apiStore from './api.store';
import { router } from '@/router';

export const useEducationInstitutionStore = defineStore({
    id: 'educationInstitution',
    state: () => ({
        educationCategories: {},
        educationInstitutionCategories: {},
        educationInstitutions: {},
        educationInstitution: {},
        categories: {},
        selectedType: {},
        selectedCategory: {},
        totalData: 0,
        totalAllData: 0,
    }),
    actions: {
        async getEducationCategories() {
            this.educationCategories = { loading: true };
            this.selectedType = {};
            this.selectedCategory = {};
            const options = {
                url: apiStore.educationInstitution.educationCategories,
                params: {
                    page: 1,
                    limit: 30,
                }
            };
            try {
                const getEducationCategoriesResult = await fetchWrapper.get(options);  
                this.educationCategories = getEducationCategoriesResult.data;
                this.educationInstitution = {};
                if(this.educationCategories) {
                    this.educationInstitutionCategories = this.educationCategories.map(item => item.type)
                    .filter((value, index, self) => self.indexOf(value) === index);
                }
            } catch (error) {
                this.educationCategories = { error };
            }
        },

        filterEducationInstitutionCategories () {
            try {
                const findInstitutionCategories = this.educationCategories.filter(v => v.type === this.selectedType);
                this.categories = findInstitutionCategories;
            } catch (error) {
                
            }
        },
        async getEducationInstitutions(search = null, page = 1, limit = 10, order = ['createdAt', 'desc'], option = []) {
            this.educationInstitutions = { loading: true };
            this.totalData = 0;
            this.totalAllData = 0;
            const options = {
                url: apiStore.educationInstitution.educationInstitutions,
                params: {
                    order: order[0],
                    sort: order[1],
                    search: search || null,
                    page,
                    limit,
                }
            };
            option.forEach((v)=> {
                if(v.regencyCode) options.params.regencyCode = v.regencyCode
                if(v.provinceCode) options.params.provinceCode = v.provinceCode
            });
            options.params.type = 'Perguruan Tinggi';
            const slug = router.currentRoute.value?.params?.slug;
         
            if (slug === 'bab-3.1-lembaga-pendidikan-menengah-bidang-kebudayaan') options.params.type = 'Sekolah';
            if (!slug) delete options.params.type;
            try {
                const getEducationInstitutionsResult = await fetchWrapper.get(options);   
                this.totalData = getEducationInstitutionsResult.totalData;
                this.totalAllData = getEducationInstitutionsResult.totalAllData;
                this.educationInstitutions = getEducationInstitutionsResult.data;   
            } catch (error) {
                this.educationInstitutions = { error };
            }
        },
        async getEducationInstitution(id) {
            const region  = useRegionStore();
            this.educationInstitution = { loading: true };
            const newUrl = apiStore.educationInstitution.educationInstitution.replace(":educationalInstitutionId", `${id}`);
            const options = {
                url: newUrl,
            };
            try {
                const getEducationInstitutionResult = await fetchWrapper.get(options);    
                this.educationInstitution = getEducationInstitutionResult.data;
                this.selectedType = getEducationInstitutionResult.data.stage.type;
                this.selectedCategory = getEducationInstitutionResult.data.stage.id;
                if (getEducationInstitutionResult.data.province) {
                    region.selectedProvince = getEducationInstitutionResult.data.province.code;
                    await region.filterRegencies(region.selectedProvince);
                }
                if (getEducationInstitutionResult.data.regency) {
                    region.selectedRegency = getEducationInstitutionResult.data.regency.code;
                    let provinceCode = getEducationInstitutionResult.data.regency.code.split(".");
                    if (region.selectedProvince == null) await region.filterRegencies(provinceCode[0]);
                }
                this.filterEducationInstitutionCategories();
            } catch (error) {
                this.educationInstitution = { error };
            }
        },
        async createEducationInstitution(payload) {
            const authStore = useAuthStore();
            const alertStore = useAlertStore();
            if (authStore.user.regencyCode) payload.regencyCode = authStore.user.regencyCode;
            if (authStore.user.provinceCode) {
                payload.provinceCode = authStore.user.provinceCode;
            } else {
                if (payload.regencyCode) payload.provinceCode = payload.regencyCode.split(".")[0];
            }
            const options = {
                url: apiStore.educationInstitution.educationInstitutionCreate,
                body: payload
            };
            try {
                await fetchWrapper.post(options);
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async updateEducationInstitution(id, payload) {
            if (payload.regency) payload.regencyCode = payload.regency.code;
            if (payload.regency) payload.provinceCode = payload.regency.code.split(".")[0];
            const alertStore = useAlertStore();
            const newUrl = apiStore.educationInstitution.educationInstitutionPatch.replace(":educationalInstitutionId", `${id}`);
            const options = {
                url: newUrl,
                body: payload
            };
            try {
                const patchEducationInstitutionResult = await fetchWrapper.patch(options); 
                this.educationInstitution = patchEducationInstitutionResult.data;  
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async deleteEducationInstitution(id, search=null, page=null, limit=null, order = ['createdAt', 'desc']) {
            const index = this.educationInstitutions.findIndex(x => x.id === id);
            if (index > -1) this.educationInstitutions[index].isDeleting = true;
            const alertStore = useAlertStore();
            const newUrl = apiStore.educationInstitution.educationInstitutionDelete.replace(":educationalInstitutionId", `${id}`);
            const options = {
                url: newUrl,
            };
            try {
                const deleteEducationInstitutionResult = await fetchWrapper.delete(options);
                await this.getEducationInstitutions(search, page, limit, order);
                alertStore.success('Data berhasil dihapus');  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
            }
        },
    }
});
