import permission  from "@/stores/permission.store";

export default {
  path: '/blogs',
  component: () => import("@/views/blogs/Layout.vue"),
  children: [
      { path: '', 
        meta: {
          iconActive: 'Blog',
          permission: permission.blog.read,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Berita',
                }
            ]
        },
        component: () => import("@/views/blogs/List.vue") 
      },
      { path: 'add', 
        meta: {
          iconActive: 'Blog',
          permission: permission.blog.create,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Berita',
                    link: '/blogs',
                },
                {
                  name: 'Tambah Berita',
                },
            ]
        },
        component: () => import("@/views/blogs/Form.vue") 
      },
      { path: 'edit/:id', 
        meta: {
          iconActive: 'Blog',
          permission: permission.blog.update,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Berita',
                    link: '/blogs'
                },
                {
                  name: 'Ubah Berita',
              },
            ]
        },
        component: () => import("@/views/blogs/Form.vue") 
      },
  ]
};
