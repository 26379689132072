import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore, useDocumentStore } from '@/stores';
import apiStore from './api.store';
import {
    router
} from '@/router';

export const useDocumentProblemContentStore = defineStore({
    id: 'documentProblemContent',
    state: () => ({
        documentProblems: {},
        documentProblem: {},
        pemajuanList: [],
        achievementIndicators : [],
        pemajuanListPick: [],
        obyekListPick: [],
        obyekList: [],
        provinceProblem: {},
        regencyProblem: {},
        totalData: 0,
        totalAllData: 0,
        opkProblemDatas: [],
    }),
    actions: {
        async getDocumentProblems(id, search = null, page = 1, limit = 10, order = ['createdAt', 'desc'], sectionSelected = null) {
            const document = useDocumentStore();
            this.documentProblems = { loading: true };
            this.documentProblem = {};
            this.totalData = 0;
            this.totalAllData = 0;
            this.achievementIndicators = [ 
                { year: 2024, indicator: '0'},
                { year: 2029, indicator: '0'},
                { year: 2034, indicator: '0'},
                { year: 2039, indicator: '0'},
            ]
            const sectionId = sectionSelected || document.documentContent.id;
            const options = {
                url: apiStore.documentProblem.documentProblems.replace(":ppkdDocumentId", `${id}`),
                params: {
                    order: order[0],
                    sort: order[1],
                    search: search || null,
                    contentId: sectionId,
                    page,
                    limit,
                }
            };
            try {
                // await this.getPemajuan();
                const getDocumentProblemResult = await fetchWrapper.get(options);   
                this.totalData = getDocumentProblemResult.totalData;
                this.totalAllData = getDocumentProblemResult.totalAllData;
                this.documentProblems = getDocumentProblemResult.data;   
                this.obyekList = [];
                this.obyekListPick = [];
                this.opkProblemDatas = [];
            } catch (error) {
                this.documentProblems = { error };
            }
        },
        async getDocumentProblem(id, problemId) {
            this.documentProblem = { loading: true };
            const newUrl = apiStore.documentProblem.documentProblem.replace(":ppkdDocumentId", `${id}`).replace(":problemAndRecommendationId", `${problemId}`);
            const options = {
                url: newUrl,
            };
            try {
                const getDocumentProblemResult = await fetchWrapper.get(options);   
                this.documentProblem = getDocumentProblemResult.data;
                this.achievementIndicators = getDocumentProblemResult.data.achievementIndicators;
                const preservations = [];
                const opks = [];
                const listOpks = [];
                getDocumentProblemResult.data.preservations.forEach(v => preservations.push(v.id));
                getDocumentProblemResult.data.opks.forEach((v) => {
                    v.text = v.name;
                    opks.push(v.id)
                    listOpks.push(v);
                });
                // const getOpks = await this.getDocumentProblemOpks(id, problemId);
                // getDocumentProblemResult.data.opkCategory.forEach(v => opks.push(v.id));
                // getDocumentProblemResult.data.opkCategory.forEach(w => listOpks.push({text: w.name, id: w.id}));
                // console.log(opks);
                // console.log(listOpks);
                // console.log(preservations);
                this.pemajuanListPick = preservations;
                this.obyekListPick = opks.slice();
                this.obyekList = listOpks.slice();
                this.opkProblemDatas = listOpks.slice();
            } catch (error) {
                this.documentProblem = { error };
            }
        },
        async createDocumentProblem(payload, id) {
            const document = useDocumentStore();
            const alertStore = useAlertStore();
            payload.ppkdDocumentContentId = document.documentContent.id;
            payload.opkIds = [];
            if (payload.opks) {
                payload.opks.forEach(v => payload.opkIds.push(v.id));
            }
            const options = {
                url: apiStore.documentProblem.documentProblemCreate.replace(":ppkdDocumentId", `${id}`),
                body: payload,
            };
            try {
                await fetchWrapper.post(options);
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async updateDocumentProblem(id, problemId, payload) {
            const document = useDocumentStore();
            const alertStore = useAlertStore();
            payload.ppkdDocumentContentId = document.documentContent.id;
            payload.opkIds = [];
            if (payload.opks) {
                payload.opks.forEach(v => payload.opkIds.push(v.id));
            }
            const newUrl = apiStore.documentProblem.documentProblemPatch.replace(":ppkdDocumentId", `${id}`).replace(":problemAndRecommendationId", `${problemId}`);
            const options = {
                url: newUrl,
                body: payload,
            };
            payload.type = 'Forum Diskusi Terpumpun';
            const slug = router.currentRoute.value.params.slug;
         
            if (slug === 'bab-8.3-notulensi-forum-terbuka') payload.type = 'Forum Terbuka';
            try {
                await fetchWrapper.patch(options); 
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async deleteDocumentProblem(id, problemId, search=null, page=null, limit=null, order = ['createdAt', 'desc']) {
            const index = this.documentProblems.findIndex(x => x.id === problemId);
            if (index > -1) this.documentProblems[index].isDeleting = true;
            const alertStore = useAlertStore();
            const newUrl = apiStore.documentProblem.documentProblemDelete.replace(":ppkdDocumentId", `${id}`).replace(":problemAndRecommendationId", `${problemId}`);;
            const options = {
                url: newUrl,
            };
            try {
                await fetchWrapper.delete(options);
                await this.getDocumentProblems(id, search, page, limit, order);
                alertStore.success('Data berhasil dihapus');  
            } catch (error) {
                console.log(`error`, error);
                this.documentProblems[index].isDeleting = false;
                alertStore.error(error);
            }
        },

        async getPemajuan() {
            this.pemajuanList = [];
            const options = {
                url: apiStore.documentProblem.pemajuan,
            };
            const result = await fetchWrapper.get(options);
            result.data.forEach(v => this.pemajuanList.push({text: v.name, id: v.id}));
        },

        // async getOpk() {
        //     this.opks = [
        //         {"id":"6c4c9ee3-bd2d-40b2-b82d-82bea0c41aa3","name":"Contoh OPK 1"},
        //         {"id":"70d1874b-ff4a-4982-a78a-95c7df0333df","name":"Contoh OPK 2"},
        //         {"id":"52377d9b-24f8-47ce-8e03-c4ffd9ab4f0d","name":"Contoh OPK 3"},
        //         {"id":"6f479294-0c2e-453d-8735-046d0a08a64f","name":"Contoh OPK 4"}
        //     ];   
        // },

        async getProvinceProblem (search, code) {
            this.provinceProblem = { loading: true };
            const newUrl = apiStore.region.provincesProblem.replace(":code", `${code}`);
            const options = {
                url: newUrl,
                params: {
                    search
                }
            };
            try {
                const getProblemResult = await fetchWrapper.get(options);
                this.provinceProblem = getProblemResult.data;
                this.provinceProblem.totalAllData = getProblemResult.totalAllData;
                this.provinceProblem.totalData = getProblemResult.totalData;
            } catch (error) {
                this.provinceProblem = { error };
            }
        },

        async getRegencyProblem (documentId, regencyCode, contentCategoryId) {
            this.regencyProblem = { loading: true };
            const newUrl = apiStore.documentProblem.documentProblemRegencies.replace(":ppkdDocumentId", `${documentId}`);
            const options = {
                url: newUrl,
                params: {
                    regencyCode,
                    contentCategoryId
                }
            };
            try {
                const getProblemResult = await fetchWrapper.get(options);
                this.regencyProblem = getProblemResult.data;
                this.regencyProblem.totalAllData = getProblemResult.totalAllData;
                this.regencyProblem.totalData = getProblemResult.totalData;
            } catch (error) {
                this.regencyProblem = { error };
            }
        },

        async createDocumentProblemFromRegency(payload) {
            const document = useDocumentStore();
            const alertStore = useAlertStore();
            const documentId = document.document.id;
            const sectionId = document.documentContent.id;
            const problemAndRecommendationIds = [];
            payload.forEach(v => problemAndRecommendationIds.push(v.id));
            const options = {
                url: apiStore.documentProblem.documentProblemCreateFromRegency.replace(":ppkdDocumentId", `${documentId}`),
                body: {
                    problemAndRecommendationIds,
                },
            };
            try {
                await fetchWrapper.post(options);
                alertStore.success();  
                await this.getDocumentProblems(documentId);
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },

        async getDocumentProblemOpks(id, problemId) {
            this.opkProblemDatas = { loading: true };
            const alertStore = useAlertStore();
            const newUrl = apiStore.documentProblem.documentProblemOpk.replace(":ppkdDocumentId", `${id}`).replace(":problemAndRecommendationId", `${problemId}`);
            const options = {
                url: newUrl,
            };
            try {
                const getDocumentProblemOpksResult =  await fetchWrapper.get(options);
                this.opkProblemDatas = getDocumentProblemOpksResult.data;
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        
    }
});
