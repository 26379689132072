import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore, useComposerStore, useInstitutionStore, useDocumentStore } from '@/stores';
import apiStore from './api.store';

export const useDocumentComposerContentStore = defineStore({
    id: 'documentComposerContent',
    state: () => ({
        documentComposers: {},
        documentComposer: {},
        structures: {},
        institutions: {},
        composers: {},
        selectedStructure: {},
        selectedInstitution: {},
        selectedPosition: {},
        selectedComposer: {},
        totalData: 0,
        totalAllData: 0,
    }),
    actions: {
        async getStructureCategories() {
            this.structures = { loading: true };
            this.selectedStructure = {};
            const options = {
                url: apiStore.documentComposer.categories,
                params: {
                    page: 1,
                    limit: 10,
                }
            };
            try {
                const getStructureCategoriesResult = await fetchWrapper.get(options);  
                this.structures = getStructureCategoriesResult.data;
            } catch (error) {
                this.structures = { error };
            }
        },
        async getComposers() {
            const composers = useComposerStore();
            const document = useDocumentStore();
            this.composers = { loading: true };
            this.selectedComposer = {};
            let option = [];
            try {
                let provinceCode = document.document.province?.code || null;
                let regencyCode = document.document.regency?.code || null;
                if(provinceCode) option.push({provinceCode: provinceCode});
                if(regencyCode) option.push({regencyCode: regencyCode});
                const getComposerResult = await composers.getComposers(null, 1, 100, [], option);
                this.composers = composers.composers;
            } catch (error) {
                console.log(error);
                this.composers = { error };
            }
        },
        async getInstitutions() {
            const document = useDocumentStore();
            const institutions = useInstitutionStore();
            this.composers = { loading: true };
            this.selectedInstitution = {};
            this.selectedPosition = {};
            let option = [];
            try {
                let provinceCode = document.document.province?.code || null;
                let regencyCode = document.document.regency?.code || null;
                if(provinceCode) option.push({provinceCode: provinceCode});
                if(regencyCode) option.push({regencyCode: regencyCode});
                const getStructureCategoriesResult = await institutions.getInstitutions(null, 1, 100, [], option)
                this.institutions = institutions.institutions;
            } catch (error) {
                this.institutions = { error };
            }
        },
        async getDocumentComposers(id, search = null, page = 1, limit = 10, order = ['createdAt', 'desc']) {
            this.documentComposers = { loading: true };
            this.documentComposer = {};
            this.totalData = 0;
            this.totalAllData = 0;
            const options = {
                url: apiStore.documentComposer.documentComposers.replace(":ppkdDocumentId", `${id}`),
                params: {
                    order: order[0],
                    sort: order[1],
                    search: search || null,
                    page,
                    limit,
                }
            };
            try {
                const getDocumentComposerResult = await fetchWrapper.get(options);   
                this.totalData = getDocumentComposerResult.totalData;
                this.totalAllData = getDocumentComposerResult.totalAllData;
                this.documentComposers = getDocumentComposerResult.data;   
            } catch (error) {
                this.documentComposers = { error };
            }
        },
        async getDocumentComposer(id, composerId) {
            this.documentComposer = { loading: true };
            const newUrl = apiStore.documentComposer.documentComposer.replace(":ppkdDocumentId", `${id}`).replace(":ppkdDocumentComposerId", `${composerId}`);
            const options = {
                url: newUrl,
            };
            try {
                const getDocumentComposerResult = await fetchWrapper.get(options);   
                this.documentComposer = getDocumentComposerResult.data;
            } catch (error) {
                this.documentComposer = { error };
            }
        },
        async createDocumentComposer(payload, id) {
            const alertStore = useAlertStore();
            const options = {
                url: apiStore.documentComposer.documentComposerCreate.replace(":ppkdDocumentId", `${id}`),
                body: payload,
                isMultipart: false,
            };
            try {
                await fetchWrapper.post(options);
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async updateDocumentComposer(id, composerId, payload) {
            const alertStore = useAlertStore();
            const newUrl = apiStore.documentComposer.documentComposerPatch.replace(":ppkdDocumentId", `${id}`).replace(":ppkdDocumentComposerId", `${composerId}`);
            const options = {
                url: newUrl,
                body: payload,
                isMultipart: false,
            };
            try {
                await fetchWrapper.patch(options); 
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async deleteDocumentComposer(id, composerId, search=null, page=null, limit=null, order = ['createdAt', 'desc']) {
            const index = this.documentComposers.findIndex(x => x.id === id);
            if (index > -1) this.documentComposers[index].isDeleting = true;
            const alertStore = useAlertStore();
            const newUrl = apiStore.documentComposer.documentComposerDelete.replace(":ppkdDocumentId", `${id}`).replace(":ppkdDocumentComposerId", `${composerId}`);;
            const options = {
                url: newUrl,
            };
            try {
                await fetchWrapper.delete(options);
                await this.getDocumentComposers(id, search, page, limit, order);
                alertStore.success('Data berhasil dihapus');  
            } catch (error) {
                console.log(`error`, error);
                this.documentComposers[index].isDeleting = false;
                alertStore.error(error);
            }
        },
        
    }
});
