import {
  createApp,
} from 'vue';
import App from './App.vue';
import {
  router
} from './router';
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Alert,
  Button,
  Collapse,
  Offcanvas,
  Tab,
} from "bootstrap";
document.title = 'PPKD 2022';
window.$ = window.jQuery = require("jquery");
window.SUMMERNOTE_DEFAULT_CONFIGS = {
  height: 600,
        toolbar: [
          ['cleaner', ['cleaner']], // The Button
          ['style', ['style']],
          ['font', ['bold', 'italic', 'underline', 'clear']],
          ['fontname', ['fontname']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['height', ['height']],
          ['table', ['table']],
          ['insert', ['picture', 'link', 'hr']],
          ['view', ['fullscreen', 'codeview']],
        ],
        cleaner: {
          action: 'paste', // both|button|paste 'button' only cleans via toolbar button, 'paste' only clean when pasting content, both does both options.
          newline: '<br>', // Summernote's default is to use '<p><br></p>'
          notStyle: 'position:absolute;top:0;left:0;right:0', // Position of Notification
          icon: '<i class="note-icon">[Reset]</i>',
          keepHtml: false, // Remove all Html formats
          keepOnlyTags: ['<p>', '<br>', '<ul>', '<li>', '<b>', '<strong>', '<i>', '<a>'], // If keepHtml is true, remove all tags except these
          keepClasses: false, // Remove Classes
          badTags: ['style', 'script', 'applet', 'embed', 'noframes', 'noscript', 'html'], // Remove full tags with contents
          badAttributes: ['style', 'start'], // Remove attributes from remaining tags
          limitChars: false, // 0/false|# 0/false disables option
          limitDisplay: 'both', // text|html|both
          limitStop: false // true/false
        },
};
// (function () {
//   if (typeof EventTarget !== "undefined") {
//       let func = EventTarget.prototype.addEventListener;
//       EventTarget.prototype.addEventListener = function (type, fn, capture) {
//           this.func = func;
//           if(typeof capture !== "boolean"){
//               capture = capture || {};
//               capture.passive = false;
//           }
//           this.func(type, fn, capture);
//       };
//   };
// }());
(function () {
  if (typeof EventTarget !== 'undefined') {
    let supportsPassive = false;
    try {
      // Test via a getter in the options object to see if the passive property is accessed
      const opts = Object.defineProperty({}, 'passive', {
        get: () => {
          supportsPassive = true;
        },
      });
      window.addEventListener('testPassive', null, opts);
      window.removeEventListener('testPassive', null, opts);
    } catch (e) {}
    const func = EventTarget.prototype.addEventListener;
    EventTarget.prototype.addEventListener = function (type, fn) {
      this.func = func;
      this.func(type, fn, supportsPassive ? { passive: false } : false);
    };
  }
})();
// window.Popper = require('@popperjs/core');

import { createPinia } from 'pinia';
import VueCookies from 'vue-cookies';
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App);
app.use(createPinia());
app.use(VueCookies);
app.use([Alert, Button, Collapse, Offcanvas, Tab]);
app.use(router);
app.use(VueAwesomePaginate);
app.use(VueSweetalert2);
app.mount('#app');
