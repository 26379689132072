const user = {
    read: 'read-user',
    create: 'create-user',
    update: 'update-user',
    delete: 'delete-user'
};

const role = {
    read: 'read-role',
    create: 'create-role',
    update: 'update-role',
    delete: 'delete-role'
};

const permission = {
    read: 'read-permission',
    create: 'create-permission',
    update: 'update-permission',
    delete: 'delete-permission'
};

const institution = {
    read: 'read-institution',
    create: 'create-institution',
    update: 'update-institution',
    delete: 'delete-institution',
};

const education = {
    read: 'read-educational-institution',
    create: 'create-educational-institution',
    update: 'update-educational-institution',
    delete: 'delete-educational-institution'
};

const regulation = {
    read: 'read-regional-regulation',
    create: 'create-regional-regulation',
    update: 'update-regional-regulation',
    delete: 'delete-regional-regulation'
};
const composer = {
    read: 'read-composer',
    create: 'create-composer',
    update: 'update-composer',
    delete: 'delete-composer'
};

const composerEducation = {
    read: 'read-composer',
    create: 'create-composer',
    update: 'update-composer',
    delete: 'delete-composer'
};

const composerWork = {
    read: 'read-composer',
    create: 'create-composer',
    update: 'update-composer',
    delete: 'delete-composer'
};

const composerPapper = {
    read: 'read-composer',
    create: 'create-composer',
    update: 'update-composer',
    delete: 'delete-composer'
};

const ppkd = {
    read: 'read-ppkd-document',
    create: 'create-ppkd-document',
    update: 'update-ppkd-document',
    delete: 'delete-ppkd-document'
};

const ppkdContent = {
    read: 'read-ppkd-document-content',
    create: 'create-ppkd-document-content',
    update: 'update-ppkd-document-content',
    delete: 'delete-ppkd-document-content'
};

const ppkdContentComposer = {
    read: 'read-ppkd-document-composer',
    create: 'create-ppkd-document-composer',
    update: 'update-ppkd-document-composer',
    delete: 'delete-ppkd-document-composer'
};

const ppkdContentRegulation = {
    read: 'read-ppkd-document-regional-regulation',
    create: 'create-ppkd-document-regional-regulation',
    update: 'update-ppkd-document-regional-regulation',
    delete: 'delete-ppkd-document-regional-regulation'
};

const ppkdContentEducation = {
    read: 'read-ppkd-document-educational-institution',
    create: 'create-ppkd-document-educational-institution',
    update: 'update-ppkd-document-educational-institution',
    delete: 'delete-ppkd-document-educational-institution'
};

const ppkdContentDetermination = {
    read: 'read-ppkd-document-determination',
    create: 'create-ppkd-document-determination',
    update: 'update-ppkd-document-determination',
    delete: 'delete-ppkd-document-determination'
};

const ppkdDocumentProblemAndRecommendation = {
    read: 'read-ppkd-document-problem-and-recommendation',
    create: 'create-ppkd-document-problem-and-recommendation',
    update: 'update-ppkd-document-problem-and-recommendation',
    delete: 'delete-ppkd-document-problem-and-recommendation'  
}

const blog = {
    read: 'read-blog',
    create: 'create-blog',
    update: 'update-blog',
    delete: 'delete-blog'  
}

const regulationBlog = {
    read: 'read-regulation',
    create: 'create-regulation',
    update: 'update-regulation',
    delete: 'delete-regulation'  
}

const problem = {
    read: 'read-ppkd-document-problem-and-recommendation', 
}

export default {
    user,
    role,
    permission,
    institution,
    education,
    regulation,
    composer,
    composerEducation,
    composerWork,
    composerPapper,
    ppkd,
    ppkdContent,
    ppkdContentComposer,
    ppkdContentRegulation,
    ppkdContentEducation,
    ppkdContentDetermination,
    ppkdDocumentProblemAndRecommendation,
    blog,
    regulationBlog,
    problem
}
