import {
    defineStore
  } from 'pinia';
  import {
    fetchWrapper
  } from '@/helpers';
  import apiStore from './api.store';
  import { useAlertStore } from './alert.store';
  
  export const useFileStore = defineStore({
    id: 'file',
    state: () => ({
     file: {}
    }),
    actions: {
          async upload(file) {
            const alertStore = useAlertStore();
            const options = {
                url: apiStore.file.fileCreate,
                body: {
                    file
                },
                isMultipart: true,
            };
            try {
                const uploadFile = await fetchWrapper.post(options);
                return uploadFile.data;
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async delete(id) {
            const alertStore = useAlertStore();
            const newUrl = apiStore.file.fileDelete.replace(":fileId", `${id}`);
            const options = {
                url: newUrl,
            };
            try {
                await fetchWrapper.delete(options);
                alertStore.success('Data berhasil dihapus');  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
            }
        },
    }
  });