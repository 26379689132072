export default {
  dashboard: {
    subCategoryAgg: "/dokumen/dashboard/subcategory-statistics",
    categoryAgg: "/dokumen/dashboard/category-statistics"
  },
  auth: {
    login: "/auth/login",
    profile: "/auth/profile",
    refreshToken: "/auth/refresh-token",
    profileRegionPatch: "/auth/profile/region",
    getProfileRegion: "/auth/profile/region",
  },
  blog: {
    blogs: "/dokumen/blogs",
    blog: "/dokumen/blogs/:blogId",
    blogCreate: "/dokumen/blogs",
    blogPatch: "/dokumen/blogs/:blogId",
    blogDelete: "/dokumen/blogs/:blogId",
    problem: "/dokumen/blogs/problem-and-recommendations",
    tags: "/dokumen/tags"
  },
  composer: {
    composers: "/dokumen/composers",
    composer: "/dokumen/composers/:composerId",
    composerCreate: "/dokumen/composers",
    composerPatch: "/dokumen/composers/:composerId",
    composerDelete: "/dokumen/composers/:composerId",

    composerEducationExperiences: "/dokumen/composers/:composerId/education-histories",
    composerEducationExperience: "/dokumen/composers/:composerId/education-histories/:educationId",
    composerEducationExperienceCreate: "/dokumen/composers/:composerId/education-histories",
    composerEducationExperiencePatch:
      "/dokumen/composers/:composerId/education-histories/:educationId",
    composerEducationExperienceDelete:
      "/dokumen/composers/:composerId/education-histories/:educationId",

    composerWorkExperiences: "/dokumen/composers/:composerId/work-experiences",
    composerWorkExperience: "/dokumen/composers/:composerId/work-experiences/:workExperienceId",
    composerWorkExperienceCreate: "/dokumen/composers/:composerId/work-experiences",
    composerWorkExperiencePatch:
      "/dokumen/composers/:composerId/work-experiences/:workExperienceId",
    composerWorkExperienceDelete:
      "/dokumen/composers/:composerId/work-experiences/:workExperienceId",

    composerPapperExperiences: "/dokumen/composers/:composerId/scientific-papers",
    composerPapperExperience: "/dokumen/composers/:composerId/scientific-papers/:papperId",
    composerPapperExperienceCreate: "/dokumen/composers/:composerId/scientific-papers",
    composerPapperExperiencePatch: "/dokumen/composers/:composerId/scientific-papers/:papperId",
    composerPapperExperienceDelete: "/dokumen/composers/:composerId/scientific-papers/:papperId",
  },
  document: {
    documents: "/dokumen/ppkd-documents",
    document: "/dokumen/ppkd-documents/:ppkdDocumentId",
    documentCreate: "/dokumen/ppkd-documents",
    documentPatch: "/dokumen/ppkd-documents/:ppkdDocumentId",
    documentDelete: "/dokumen/ppkd-documents/:ppkdDocumentId",
    categories: "/dokumen/ppkd-document-content-categories",
    readStatus: "/dokumen/ppkd-documents/:ppkdDocumentId/contents/status",
    verification: "/dokumen/ppkd-documents/:ppkdDocumentId/verification",
    presentVerification: "/dokumen/ppkd-documents/:ppkdDocumentId/present-verification",
    cancelPresentVerification: "/dokumen/ppkd-documents/:ppkdDocumentId/cancel-present-verification",
    upgrade: "/dokumen/ppkd-documents/:ppkdDocumentId/upgrade",
    download: "/dokumen/ppkd-documents/:ppkdDocumentId/download",
  },
  documentContent: {
    contents: "/dokumen/ppkd-documents/:ppkdDocumentId/contents",
    content: "/dokumen/ppkd-documents/:ppkdDocumentId/contents/:ppkdDocumentContentId",
    contentCreate: "/dokumen/ppkd-documents/:ppkdDocumentId/contents",
    contentPatch: "/dokumen/ppkd-documents/:ppkdDocumentId/contents/:contentId",
    contentDelete: "/dokumen/ppkd-documents/:ppkdDocumentId/contents/:contentId",
    contentSubmitBySlug: "/dokumen/ppkd-documents/:ppkdDocumentId/contents/category-slug/:slug",
    contentBySlug: "/dokumen/ppkd-documents/:ppkdDocumentId/contents/category-slug/:slug",
  },
  documentComposer: {
    documentComposers: "/dokumen/ppkd-documents/:ppkdDocumentId/composers",
    documentComposer: "/dokumen/ppkd-documents/:ppkdDocumentId/composers/:ppkdDocumentComposerId",
    documentComposerCreate: "/dokumen/ppkd-documents/:ppkdDocumentId/composers",
    documentComposerPatch:
      "/dokumen/ppkd-documents/:ppkdDocumentId/composers/:ppkdDocumentComposerId",
    documentComposerDelete:
      "/dokumen/ppkd-documents/:ppkdDocumentId/composers/:ppkdDocumentComposerId",
    categories: "/dokumen/ppkd-document-composer-structures",
  },
  documentEducation: {
    documentEducations: "/ppkd-documents/:ppkdDocumentId/educational-institutions",
    documentEducation:
      "/ppkd-documents/:ppkdDocumentId/educational-institutions/:ppkdDocumentEducationId",
    documentEducationCreate: "/ppkd-documents/:ppkdDocumentId/educational-institutions",
    documentEducationPatch:
      "/ppkd-documents/:ppkdDocumentId/educational-institutions/:ppkdDocumentEducationId",
    documentEducationDelete:
      "/ppkd-documents/:ppkdDocumentId/educational-institutions/:ppkdDocumentEducationId",
  },
  documentDetermination: {
    documentDeterminations: "/dokumen/ppkd-documents/:ppkdDocumentId/document-determinations",
    documentDetermination:
      "/dokumen/ppkd-documents/:ppkdDocumentId/document-determinations/:ppkdDocumentDeterminationId",
    documentDeterminationCreate: "/dokumen/ppkd-documents/:ppkdDocumentId/document-determinations",
    documentDeterminationPatch:
      "/dokumen/ppkd-documents/:ppkdDocumentId/document-determinations/:ppkdDocumentDeterminationId",
  },
  documentProblem: {
    documentProblems: "/dokumen/ppkd-documents/:ppkdDocumentId/problem-and-recommendations",
    documentProblem:
      "/dokumen/ppkd-documents/:ppkdDocumentId/problem-and-recommendations/:problemAndRecommendationId",
    documentProblemCreate: "/dokumen/ppkd-documents/:ppkdDocumentId/problem-and-recommendations",
    documentProblemPatch:
      "/dokumen/ppkd-documents/:ppkdDocumentId/problem-and-recommendations/:problemAndRecommendationId",
    documentProblemDelete:
      "/dokumen/ppkd-documents/:ppkdDocumentId/problem-and-recommendations/:problemAndRecommendationId",
    pemajuan: "/dokumen/preservations",
    documentProblemRegencies: "/dokumen/ppkd-documents/:ppkdDocumentId/problem-and-recommendations/regencies",
    documentProblemCreateFromRegency: "/dokumen/ppkd-documents/:ppkdDocumentId/problem-and-recommendations/regencies",
    documentProblemOpk: "/dokumen/ppkd-documents/:ppkdDocumentId/problem-and-recommendations/:problemAndRecommendationId/all-opks"
  },
  documentForum: {
    documentForums: "/dokumen/ppkd-documents/:ppkdDocumentId/forum-notes",
    documentForum: "/dokumen/ppkd-documents/:ppkdDocumentId/forum-notes/:ppkdDocumentForumId",
    documentForumCreate: "/dokumen/ppkd-documents/:ppkdDocumentId/forum-notes",
    documentForumPatch: "/dokumen/ppkd-documents/:ppkdDocumentId/forum-notes/:ppkdDocumentForumId",
    documentForumDelete: "/dokumen/ppkd-documents/:ppkdDocumentId/forum-notes/:ppkdDocumentForumId",
    documentForumPhotos:
      "/dokumen/ppkd-documents/:ppkdDocumentId/forum-notes/:ppkdDocumentForumId/photos",
    documentForumPhotoCreate:
      "/dokumen/ppkd-documents/:ppkdDocumentId/forum-notes/:ppkdDocumentForumId/photos",
    documentForumPhotoPatch:
      "/dokumen/ppkd-documents/:ppkdDocumentId/forum-notes/:ppkdDocumentForumId/photos/:photoId",
    documentForumPhotoDelete:
      "/dokumen/ppkd-documents/:ppkdDocumentId/forum-notes/:ppkdDocumentForumId/photos/:photoId",
  },
  dapobud: {
    collections: "dapobud/collections",
    categories: "dapobud/categories",
    categoryByAbbr: "/dapobud/categories/abbr/:dapobudCategoryAbbr",
    subCategories: "dapobud/categories/:dapobudCategoryId/subcategories",
    subCategoryByName: "/dapobud/subcategories/name/:subCategoryName",
    subCategoryAll: "dapobud/subcategories",
    datas: "dapobud/datas",
    aggregations: "dapobud/collections/:dapobudCollectionId/aggregations",
    aggregationsByCategory: "/dapobud/categories/:dapobudcategoryId/aggregations",
    aggregationsBySubCategory: "/dapobud/subcategories/:dapobudSubCategoryId/aggregations",
    aggregationsByCollection: "dapobud/collections/:dapobudCollectionId/aggregations",
    dataByCollection: "/dapobud/collections/:dapobudCollectionId/datas"
  },
  educationInstitution: {
    educationCategories: "/dokumen/educations",
    educationInstitutions: "/dokumen/educational-institutions",
    educationInstitution: "/dokumen/educational-institutions/:educationalInstitutionId",
    educationInstitutionCreate: "/dokumen/educational-institutions",
    educationInstitutionPatch: "/dokumen/educational-institutions/:educationalInstitutionId",
    educationInstitutionDelete: "/dokumen/educational-institutions/:educationalInstitutionId",
  },
  institution: {
    institutionCategories: "/dokumen/institution-categories",
    institutions: "/dokumen/institutions",
    institution: "/dokumen/institutions/:institutionId",
    institutionCreate: "/dokumen/institutions",
    institutionPatch: "/dokumen/institutions/:institutionId",
    institutionDelete: "/dokumen/institutions/:institutionId",
  },
  permission: {
    permissions: "/permissions",
    permission: "/permission/:permissionId",
  },
  region: {
    provinces: "/provinces",
    regencies: "/regencies",
    regenciesProvince: "/provinces/:code/regencies",
    provincesProblem: "/provinces/:code/problem-and-recommendations",
    regenciesProblem: "/regencies/:code/problem-and-recommendations",
  },
  regulation: {
    regulations: "/dokumen/regional-regulations",
    regulation: "/dokumen/regional-regulations/:regionalRegulationId",
    regulationCreate: "/dokumen/regional-regulations",
    regulationPatch: "/dokumen/regional-regulations/:regionalRegulationId",
    regulationDelete: "/dokumen/regional-regulations/:regionalRegulationId",
  },
  role: {
    roles: "/roles",
    role: "/roles/:roleId",
    roleCreate: "/roles",
    rolePatch: "/roles/:roleId",
    roleDelete: "/roles/:roleId",
  },
  statusAttribute: {
    religions: "/dokumen/composers/religions",
    marriageStatuses: "/dokumen/composers/marriage-status",
  },
  user: {
    users: "/users",
    user: "/users/:userId",
    userCreate: "/users",
    userPatch: "/users/:userId",
    userDelete: "/users/:userId",
  },
  file: {
    fileCreate: "/files",
    fileDelete: "/files/:fileId",
  },
  regulationBlog: {
    regulations: "/dokumen/regulations",
    regulation: "/dokumen/regulations/:regulationId",
    regulationCreate: "/dokumen/regulations",
    regulationPatch: "/dokumen/regulations/:regulationId",
    regulationDelete: "/dokumen/regulations/:regulationId",
    regulationType: "/dokumen/regulation-types",
  },
};
