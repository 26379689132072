import permission  from "@/stores/permission.store";

export default {
  path: '/nomenklatur-jabatan',
  component: () => import("@/views/institutions/Layout.vue"),
  children: [
      { path: '', 
        meta: {
          iconActive: 'nomenKlaturJabatan',
          permission: permission.institution.read,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Nomenklatur Jabatan',
                }
            ]
        },
        component: () => import("@/views/institutions/List.vue") 
      },
      { path: 'add', 
        meta: {
          iconActive: 'nomenKlaturJabatan',
          permission: permission.institution.create,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Nomenklatur Jabatan',
                    link: '/nomenklatur-jabatan',
                },
                {
                  name: 'Tambah Nomenklatur Jabatan',
                },
            ]
        },
        component: () => import("@/views/institutions/Form.vue") 
      },
      { path: 'edit/:id', 
        meta: {
          iconActive: 'nomenKlaturJabatan',
          permission: permission.institution.update,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Nomenklatur Jabatan',
                    link: '/nomenklatur-jabatan'
                },
                {
                  name: 'Ubah Nomenklatur Jabatan',
              },
            ]
        },
        component: () => import("@/views/institutions/Form.vue") 
      },
  ]
};
