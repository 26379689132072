import permission  from "@/stores/permission.store";

export default {
  path: '/profil-tim-penyusun',
  component: () => import("@/views/composers/histories/Layout.vue"),
  children: [
      { path: ':id/history/:name', 
        meta: {
          iconActive: 'profilTimPenyusun',
          permission: permission.composerEducation.read,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Profil Tim Penyusun',
                    link: '/profil-tim-penyusun',
                },
                {
                    name: 'Riwayat',
                }
            ]
        },
        component: () => import("@/views/composers/histories/List.vue") 
      },
  ]
};
