import {
    defineStore
} from 'pinia';
import {
    useAlertStore
} from '@/stores';

import {
    fetchWrapper
} from '@/helpers';
import {
    router
} from '@/router';
import apiStore from './api.store';

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        permissions: JSON.parse(localStorage.getItem('permissions')),
        permissionList: {},
        profileRegion: {},
        returnUrl: null,
    }),
    actions: {
        async login(username, password, rememberLogin) {
            const alertStore = useAlertStore();
            try {
                const options = {
                    url: apiStore.auth.login,
                    body: {
                        username,
                        password
                    },
                }
                const user = await fetchWrapper.post(options);
                if(rememberLogin == true) {
                    await window.$cookies.set('ppkd-remember-login', true);
                    await window.$cookies.set('ppkd-at', user.data.accessToken, `${user.data.accessTokenExpiresIn}s`)
                    .set('ppkd-rt', user.data.refreshToken, `${user.data.refreshTokenExpiresIn}s`);
                } else {
                    await window.$cookies.set('ppkd-remember-login', false);
                    await window.$cookies.set('ppkd-at', user.data.accessToken, `${user.data.accessTokenExpiresIn}s`)
                    .set('ppkd-rt', user.data.refreshToken, 0);
                }
                await this.getProfile();

                // redirect to previous url or default to home page
                router.push(this.returnUrl || '/');
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
            }
        },
        async checkAuth() {
            const alertStore = useAlertStore();
            const refreshToken = await window.$cookies.get('ppkd-rt');
            const rememberLogin = await window.$cookies.get('ppkd-remember-login');
            if (!refreshToken) return false;
            try {
                const options = {
                    url: apiStore.auth.refreshToken,
                    headers: {
                        Authorization: `Bearer ${refreshToken}`
                    }
                }
                const reqToken = await fetchWrapper.post(options);
                if (rememberLogin == true) {
                    await window.$cookies.set('ppkd-at', reqToken.data.accessToken, `${reqToken.data.accessTokenExpiresIn}s`)
                    .set('ppkd-rt', reqToken.data.refreshToken, `${reqToken.data.refreshTokenExpiresIn}s`);
                } else {
                    await window.$cookies.set('ppkd-at', reqToken.data.accessToken, `${reqToken.data.accessTokenExpiresIn}s`)
                    .set('ppkd-rt', reqToken.data.refreshToken, 0);
                }
              
                await this.getProfile();
                return true;
            } catch (error) {
                alertStore.error(`Gagal mengupdate token`);
                this.logout();
            }
        },
        async getProfile() {
            const alertStore = useAlertStore();
            try {
                const options = {
                    url: apiStore.auth.profile,
                }
                const profile = await fetchWrapper.get(options);
                const permissionMerge = [];
                const permission = profile.data.roleUsers;
                permission.map( v => {
                    v.role.permissions.map(vp => {
                        permissionMerge.push(vp.slug);
                    })
                });
                // update pinia state
                this.user = profile.data;
                this.permissions = permissionMerge;
                profile.data.userHasRegion = false;
                let userHasRegion = profile.data.roleUsers.findIndex((v) => v.role.hasRegion === true);
                let userHasRegency = false;
                let userHasProvince = false;
                if (userHasRegion > -1) {
                    userHasRegency =  profile.data.roleUsers.findIndex((v) => v.regency);
                    userHasProvince =  profile.data.roleUsers.findIndex((v) => v.province);
                    if(userHasRegency > -1) {
                        profile.data.userHasRegency = true;
                        profile.data.regencyCode = profile.data.roleUsers[userHasRegency].regency.code;
                        profile.data.regencyName = profile.data.roleUsers[userHasRegency].regency.name;
                    }
                    if(userHasProvince > -1) {
                        profile.data.userHasProvince = true;
                        profile.data.provinceCode = profile.data.roleUsers[userHasProvince].province.code;
                        profile.data.provinceName = profile.data.roleUsers[userHasProvince].province.name;
                    }
                    profile.data.userHasRegion = true;
                } 

                // store user details
                localStorage.setItem('user', JSON.stringify(profile.data));
                localStorage.setItem('permissions', JSON.stringify(permissionMerge));
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                this.logout();
            }
        },
        async logout() {
            this.user = null;
            this.permissions = null;
            await window.$cookies.keys().forEach(cookie => window.$cookies.remove(cookie))
            localStorage.clear();
            router.push('/auth/login');
        },
        async getAllPermission(search = null, page = 1, limit = 200, order = ['moduleId', 'asc']) {
            const alertStore = useAlertStore();
            this.permissionList =  { loading: true };
            try {
                const options = {
                    url: apiStore.permission.permissions,
                    params: {
                        order: order[0],
                        sort: order[1],
                        search: search || null,
                        page,
                        limit,
                    }
                }
                const permission = await fetchWrapper.get(options);
                if (permission.data.length > 0) {
                    const results = permission.data.reduce(function(results, data) {
                        const arrName = data.name.split(" ");
                        arrName.shift();
                        let uniqueName = arrName.join(" ");
                        (results[uniqueName] = results[uniqueName] || []).push(data);
                        return results;
                    }, {});
                    this.permissionList = results;
                    // console.log(results);
                }
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
            }
        },
        async updateProfileRegion (payload) {
            const alertStore = useAlertStore();
            const newUrl = apiStore.auth.profileRegionPatch;
            const options = {
                url: newUrl,
                body: payload
            };
            try {
                await fetchWrapper.patch(options); 
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async getProfileRegion() {
            this.profileRegion = { loading: true};
            const alertStore = useAlertStore();
            try {
                const options = {
                    url: apiStore.auth.getProfileRegion,
                }
               const getProfileRegionResult =  await fetchWrapper.get(options);
               this.profileRegion = getProfileRegionResult.data;
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
            }
        },
    }
});