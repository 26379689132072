<script setup>
import Header from "./views/layouts/Header.vue";
import Footer from "./views/layouts/Footer.vue";
import Breadcrumb from "./components/Breadcrumb.vue";
import Alert from "./components/Alert.vue";

import { useAuthStore } from "@/stores";
import { provide } from "@vue/runtime-core";
const authStore = useAuthStore();
// export default {
//   components: { Header, Footer, Breadcrumb, Alert }
// }
</script>

<style>
.title-page {
  margin-bottom: 44px;
  font-weight: 600;
}

h1 {
  font-weight: 700 !important;
  font-size: 34px !important;
}

h2 {
  font-weight: 700 !important;
  font-size: 26px !important;
}

h3 {
  font-weight: 700 !important;
  font-size: 22px !important;
}

h4 {
  font-weight: 700 !important;
  font-size: 18px !important;
}

body {
  background-color: #f2f4f9 !important;
  /* background-color: #fff !important; */
}

table {
  border-collapse: collapse;
  padding-top: 0.75rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  color: #46484e;
  width: 100%;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
}

table thead tr {
  height: 49px;
  border-bottom: 1px solid #1d6cff !important;
}

table thead tr th {
  background-color: transparent !important;
  border-bottom: 1px solid #1d6cff !important;
  font-size: 11px !important;
  font-weight: 600;
  text-transform: uppercase;
}

table th,
table td {
  padding: 0.5rem 1rem !important;
  text-align: left;
  vertical-align: middle;
}

table tbody tr {
  border-bottom: 1px solid #e0e1e7 !important;
}

table tbody tr td {
  font-size: 11px !important;
  color: #46484e !important;
}
</style>

<template>
  <Header v-if="authStore.user"></Header>
  <div class="container-fluid pt-2 pb-2">
    <Breadcrumb></Breadcrumb>
    <Alert />
    <router-view />
  </div>
  <Footer></Footer>
</template>
