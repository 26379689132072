<script>
import iconSvg from "../../assets/svg-icon";
import { useAuthStore } from "@/stores";
const menu = [
  {
    id: "1",
    name: "Dashboard",
    iconMatch: "dashboard",
    linkTo: "/",
    icon: iconSvg.dashboard,
    iconActive: iconSvg.dashboardActive,
    permission: "read-ppkd-document",
  },
  {
    id: "2",
    name: "Dokumen PPKD",
    linkTo: "/dokumen",
    iconMatch: "dokumen",
    icon: iconSvg.dokumen,
    iconActive: iconSvg.dokumenActive,
    permission: "read-ppkd-document",
  },
  {
    id: "3",
    name: "Data Dapobud",
    isHaveChild: true,
    childData: [
      {
        id: "3-1",
        name: "Manuskrip",
        linkTo: "/dapobud/manuskrip",
      },
      {
        id: "3-2",
        name: "Adat Istiadat",
        linkTo: "/dapobud/adat-istiadat",
      },
      {
        id: "3-3",
        name: "Ritus",
        linkTo: "/dapobud/ritus",
      },
      {
        id: "3-4",
        name: "Pengetahuan Tradisional",
        linkTo: "/dapobud/pengetahuan-tradisional",
      },
      {
        id: "3-5",
        name: "Teknologi Tradisional",
        linkTo: "/dapobud/teknologi-tradisional",
      },
      {
        id: "3-6",
        name: "Seni",
        linkTo: "/dapobud/seni",
      },
      {
        id: "3-7",
        name: "Tradisi Lisan",
        linkTo: "/dapobud/tradisi-lisan",
      },
      {
        id: "3-8",
        name: "Bahasa",
        linkTo: "/dapobud/bahasa",
      },
      {
        id: "3-9",
        name: "Permainan Rakyat",
        linkTo: "/dapobud/permainan-rakyat",
      },
      {
        id: "3-10",
        name: "Olahraga Tradisional",
        linkTo: "/dapobud/olahraga-tradisional",
      },

      {
        id: "3-11",
        name: "Cagar Budaya",
        linkTo: "/dapobud/cagar-budaya",
      },
    ],
    linkTo: "/dokumen",
    icon: iconSvg.dapobud,
    iconActive: iconSvg.dapobudActive,
    permission: "read-ppkd-document",
  },
  {
    id: "4",
    name: "Nomenklatur Jabatan",
    linkTo: "/nomenklatur-jabatan",
    iconMatch: "nomenKlaturJabatan",
    icon: iconSvg.nomenKlaturJabatan,
    iconActive: iconSvg.nomenKlaturJabatanActive,
    permission: "read-institution",
  },
  {
    id: "5",
    name: "Profil Tim Penyusun",
    linkTo: "/profil-tim-penyusun",
    iconMatch: "profilTimPenyusun",
    icon: iconSvg.profilTimPenyusun,
    iconActive: iconSvg.profilTimPenyusunActive,
    permission: "read-composer",
  },
  {
    id: "6",
    name: "Peraturan Daerah",
    linkTo: "/peraturan-daerah",
    iconMatch: "peraturanDaerah",
    icon: iconSvg.peraturanDaerah,
    iconActive: iconSvg.peraturanDaerahActive,
    permission: "read-regional-regulation",
  },
  {
    id: "7",
    name: "Lembaga Pendidikan Kebudayaan",
    linkTo: "/lembaga-pendidikan",
    iconMatch: "lembagaPendidikan",
    icon: iconSvg.lembagaPendidikan,
    iconActive: iconSvg.lembagaPendidikanActive,
    permission: "read-educational-institution",
  },
  {
    id: "10",
    name: "Permasalahan dan Rekomendasi",
    linkTo: "/problem",
    iconMatch: "problem",
    icon: iconSvg.pelaksanaanRekomendasi,
    iconActive: iconSvg.pelaksanaanRekomendasiActive,
    permission: "read-ppkd-document-problem-and-recommendation",
  },
  {
    id: "8",
    name: "Berita",
    linkTo: "/blogs",
    iconMatch: "Blog",
    icon: iconSvg.pelaksanaanRekomendasi,
    iconActive: iconSvg.pelaksanaanRekomendasiActive,
    permission: "read-blog",
  },
  {
    id: "9",
    name: "Regulasi",
    linkTo: "/regulasi",
    iconMatch: "Regulasi",
    icon: iconSvg.pelaksanaanRekomendasi,
    iconActive: iconSvg.pelaksanaanRekomendasiActive,
    permission: "read-regulation",
  },
  {
    id: "11",
    name: "Pengaturan Sistem",
    isHaveChild: true,
    iconMatch: "Pengguna",
    childData: [
      {
        id: "9-1",
        name: "Users",
        linkTo: "/users",
        permission: "read-user",
      },
      {
        id: "9-2",
        name: "Hak Akses",
        linkTo: "/roles",
        permission: "read-roles",
      },
    ],
    linkTo: "#",
    icon: iconSvg.setting,
    iconActive: iconSvg.settingActive,
    permission: "read-user",
  },
];
let newMenu = [];
export default {
  data() {
    return {
      authStoreData: {},
      newMenu: [],
      authStore: {},
      iconPerson: iconSvg.person,
    };
  },
  created() {
    const authStore = useAuthStore();
    this.authStore = authStore;
    this.authStoreData.user = JSON.parse(JSON.stringify(authStore.user));
    if (authStore.permissions) {
      const permission = JSON.parse(JSON.stringify(authStore.permissions));
      menu.map((v) => {
        if (permission.includes(v.permission)) {
          this.newMenu.push(v);
        }
      });
    }
  },
  methods: {
    changePage() {
      $(".offcanvas").hide();
    },
    openMenu() {
      $(".offcanvas").show();
    },
  },
};
</script>

<template>
  <nav v-if="authStoreData.user" class="navbar navbar-light bg-light bg-shadow">
    <div class="container">
      <button class="btn btn-default" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebar-ppkd"
        aria-controls="sidebar-ppkd">
        <!--?xml version="1.0" ?--><svg style="color: rgb(41, 43, 47)" height="32px" id="Layer_1" version="1.1"
          viewBox="0 0 32 32" width="32px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <path
            d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2 s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2 S29.104,22,28,22z"
            fill="#292b2f"></path>
        </svg>
        <span class="offcanvas-title"> PPKD Dokumen</span>
      </button>

      <div class="offcanvas offcanvas-start" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1"
        id="sidebar-ppkd" aria-labelledby="sidebar-ppkdLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="sidebar-ppkdLabel">PPKD Dokumen</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <div id="sidebar-ppkd">
            <ul class="list-group" v-for="item in newMenu" :key="item.id">
              <router-link :to="item.linkTo" class="a-link" @click="icon = item.icon" v-if="!item.isHaveChild">
                <li class="list-group-item">
                  <!-- <span class="item-icon"><img :src="require(`@/../public/assets/icon-menu/${item.icon}`)"></span> -->
                  <span class="item-icon" v-html="item.iconActive"
                    v-if="this.$route.meta.iconActive == item.iconMatch"></span>
                  <span class="item-icon" v-html="item.icon" v-else></span>
                  {{ item.name }}
                </li>
              </router-link>
              <li class="list-group-item list-child" v-if="item.isHaveChild">
                <div class="accordion accordion-flush" :id="`accordionFlushExample` + item.id">
                  <div class="accordion-item">
                    <h2 class="accordion-header" :id="`flush-headingOne` + item.id">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        :data-bs-target="`#flush-collapseOne` + item.id" aria-expanded="false"
                        aria-controls="flush-collapseOne">
                        <span class="item-icon icon-accordion" v-html="item.iconActive"
                          v-if="this.$route.meta.iconActive == item.iconMatch"></span>
                        <span class="item-icon icon-accordion" v-html="item.icon" v-else></span>
                        {{ item.name }}
                      </button>
                    </h2>
                    <div :id="`flush-collapseOne` + item.id" class="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne" :data-bs-parent="`#accordionFlushExample` + item.id">
                      <div class="accordion-body">
                        <ul class="list-group list-child" style="list-style: none; margin-left: 80px">
                          <router-link v-for="itemChild in item.childData" :key="itemChild.id" :to="itemChild.linkTo"
                            class="a-link">
                            <span class="item-icon"></span>
                            <li>
                              {{ itemChild.name }}
                            </li>
                          </router-link>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <span class="profile-user">{{ authStoreData.user.account.name }} |
          {{ authStoreData.user.roleUsers[0]?.role.name }}</span>

        <div class="dropdown dropstart">
          <span v-html="iconPerson" data-bs-toggle="dropdown" aria-expanded="false"></span>
          <ul class="dropdown-menu person">
            <li>
              <span class="dropdown-item">
                {{ authStoreData.user.account.name }}
                <span class="ms-5" v-if="authStore.user.userHasRegion">
                  <router-link :to="`/users/profil-region`" class="a-link">
                    Atur Profil Budaya
                  </router-link>
                </span>
              </span>
            </li>
            <li>
              <span class="dropdown-item" v-for="role in authStoreData.user.roleUsers" :key="role.id">{{
                role.role.name
              }}</span>
            </li>
            <li>
              <span class="dropdown-item" v-if="authStoreData.user.roleUsers[0]?.province">{{
                authStoreData.user.roleUsers[0]?.province.name
              }}</span>
            </li>
            <li>
              <span class="dropdown-item" v-if="authStoreData.user.roleUsers[0]?.regency">{{
                authStoreData.user.roleUsers[0]?.regency.name
              }}</span>
            </li>
            <li>
              <span class="dropdown-item"><button class="btn btn-sm btn-danger btn-logout" @click="authStore.logout()"
                  type="button">
                  Log Out
                </button></span>
            </li>
          </ul>
        </div>

        <!-- <button class="btn btn-sm btn-primary" @click="authStore.logout()" type="button">LogOut</button> -->
      </div>
    </div>
  </nav>
</template>
<style scoped>
.offcanvas-title {
  color: #292b2f;
  font-size: 18px;
  font-weight: 700;
  margin-left: 17px;
}

.offcanvas-body {
  padding: 0px;
}

.accordion-body {
  padding: 0px;
  font-size: 16px;
}

.accordion-button {
  color: #1d6cff;
  padding: 0px;
  background-color: transparent;
  font-size: 16px;
}

.accordion-button:not(.collapsed) {
  color: #1d6cff;
  background-color: transparent;
  box-shadow: none;
}

.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.list-group-item {
  padding: 12px;
  margin: 2px;
  background: transparent;
  color: #1d6cff;
  font-size: 16px;
  border-radius: 1px !important;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom-color: #ffffff21;
  cursor: pointer;
  text-decoration: none;
}

.a-link {
  text-decoration: none;
}

.a-link.active {
  text-decoration: none;
  background-color: #f2f4f9;
  font-weight: 700;
}

.list-group-item:focus {
  background-color: #f2f4f9;
}

.list-group-item:hover {
  background-color: #f2f4f9;
}

.list-child>.list-group-item {
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom-color: transparent;
  background: transparent;
}

.list-child {
  background-color: transparent;
}

.list-child:hover {
  background-color: transparent;
}

.list-child>.list-group-item:hover {
  border: 0px;
  background: #f2f4f9;
}

.list-child>.list-group-item:focus {
  border: 0px;
  background: #f2f4f9;
}

.bg-shadow {
  box-shadow: 0px 4px 24px -4px rgba(150, 155, 164, 0.4);
  -webkit-box-shadow: 0px 4px 24px -4px rgba(150, 155, 164, 0.4);
  -moz-box-shadow: 0px 4px 24px -4px rgba(150, 155, 164, 0.4);
}

.bg-light {
  background-color: #ffffff !important;
}

.item-icon {
  margin-right: 17.5px;
  margin-left: 17.5px;
  display: -webkit-inline-box;
  width: 30px;
}

.profile-user {
  font-size: 14px;
  margin-top: 2px;
  margin-right: 20px;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 50px !important;
  --bs-dropdown-min-width: 12rem;
}

span.dropdown-item {
  font-size: 14px;
}

span.dropdown-item:hover {
  background-color: transparent;
}

.btn-logout {
  padding: 2px;
  width: 100%;
  font-size: 14px;
}

.icon-accordion {
  margin-right: 21px;
}
</style>
