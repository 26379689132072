import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore, useRegionStore } from '@/stores';
import apiStore from './api.store';

export const useComposerStore = defineStore({
    id: 'composer',
    state: () => ({
        composers: {},
        composer: {},
        totalData: 0,
        totalAllData: 0,
    }),
    actions: {
        async getComposers(search = null, page = 1, limit = 10, order = ['createdAt', 'desc'], option = []) {
            this.composers = { loading: true };
            this.composer = {};
            this.totalData = 0;
            this.totalAllData = 0;
            const options = {
                url: apiStore.composer.composers,
                params: {
                    order: order[0],
                    sort: order[1],
                    search: search || null,
                    page,
                    limit,
                }
            };
            option.forEach((v)=> {
                if(v.regencyCode) options.params.regencyCode = v.regencyCode
                if(v.provinceCode) options.params.provinceCode = v.provinceCode
            });
            try {
                const getComposersResult = await fetchWrapper.get(options);   
                this.totalData = getComposersResult.totalData;
                this.totalAllData = getComposersResult.totalAllData;
                this.composers = getComposersResult.data;   
            } catch (error) {
                this.composers = { error };
            }
        },
        async getComposer(id) {
            const region  = useRegionStore();
            this.composer = { loading: true };
            const newUrl = apiStore.composer.composer.replace(":composerId", `${id}`);
            const options = {
                url: newUrl,
            };
            try {
                const getComposerResult = await fetchWrapper.get(options);    
                this.composer = getComposerResult.data;
                if (getComposerResult.data.province) {
                    region.selectedProvince = getComposerResult.data.province.code;
                    await region.filterRegencies(region.selectedProvince);
                }
                if (getComposerResult.data.regency) {
                    region.selectedRegency = getComposerResult.data.regency.code;
                    let provinceCode = getComposerResult.data.regency.code.split(".");
                    if (region.selectedProvince == null) await region.filterRegencies(provinceCode[0]);
                }
            } catch (error) {
                this.composer = { error };
            }
        },
        async createComposer(payload) {
            const alertStore = useAlertStore();
            const options = {
                url: apiStore.composer.composerCreate,
                body: payload,
            };
            try {
                const createResult = await fetchWrapper.post(options);
                alertStore.success(); 
                return createResult; 
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async updateComposer(id, payload) {
            const alertStore = useAlertStore();
            const newUrl = apiStore.composer.composerPatch.replace(":composerId", `${id}`);
            const options = {
                url: newUrl,
                body: payload,
            };
            try {
                await fetchWrapper.patch(options); 
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async deleteComposer(id,  search = null, page = null, limit = null, order = ['createdAt', 'desc']) {
            const index = this.composers.findIndex(x => x.id === id);
            if (index > -1) this.composers[index].isDeleting = true;
            const alertStore = useAlertStore();
            const newUrl = apiStore.composer.composerDelete.replace(":composerId", `${id}`);
            const options = {
                url: newUrl,
            };
            try {
                await fetchWrapper.delete(options);
                await this.getComposers(search, page, limit, order);
                alertStore.success('Data berhasil dihapus');  
            } catch (error) {
                this.composers[index].isDeleting = false;
                console.log(`error`, error);
                alertStore.error(error);
            }
        },
    }
});
