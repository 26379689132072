import { defineStore } from "pinia";
import { fetchWrapper } from "@/helpers";
import apiStore from "./api.store";
import { router } from "@/router";
import { useDocumentStore } from "./document.store";

export const useDapobudDocumentStore = defineStore({
  id: "dapobud-document",
  state: () => ({
    categories: [],
    subCategories: [],
    selectedSubCategory: {},
    selectedCategory: {},
    selectedCategoryLK: {},
    selectedCategoryTB: {},
    datas: {},
    datasLK: {},
    datasTB: {},
    totalDataTB: 0,
    totalDataLK: 0,
    totalAllDataTB: 0,
    totalAllDataLK: 0,
    totalData: 0,
    totalAllData: 0,
    aggregations: {},
  }),
  actions: {
    async getCategories() {
      this.categories = { loading: true };
      this.totalData = 0;
      this.totalAllData = 0;
      const options = {
        url: apiStore.dapobud.categories,
      };
      try {
        const getCategoriesResult = await fetchWrapper.get(options);
        this.categories = getCategoriesResult.data;
      } catch (error) {
        this.categories = { error };
      }
    },

    async getSubCategories(categoryId) {
      this.subCategories = { loading: true };
      this.totalData = 0;
      this.totalAllData = 0;
      const options = {
        url: apiStore.dapobud.subCategories.replace(":dapobudCategoryId", categoryId),
      };
      try {
        const getSubCategoriesResult = await fetchWrapper.get(options);
        this.subCategories = getSubCategoriesResult.data;
      } catch (error) {
        this.subCategories = { error };
      }
    },

    async getSubCategoriesByTag(tag, chapter) {
      this.selectedSubCategory = { loading: true };
      this.totalData = 0;
      this.totalAllData = 0;
      const options = {
        url: apiStore.dapobud.subCategoryAll,
        params: {
          page: 1,
          limit: 100,
        },
      };
      try {
        const getSubCategoriesResult = await fetchWrapper.get(options);

        if (chapter && tag) {
          // console.log(chapter, tag);
          let findCategory = [];
          switch (chapter.toString()) {
            case "4":
            case "7":
              switch (tag.toString()) {
                case "Bab 4.1":
                case "Bab 7.1.1":
                  findCategory = getSubCategoriesResult.data.filter(
                    (v) => v.category.abbr === "OP" && v.name === "MANUSKRIP"
                  );
                  this.selectedSubCategory = findCategory[0];
                  break;
                case "Bab 4.2":
                case "Bab 7.1.2":
                  findCategory = getSubCategoriesResult.data.filter(
                    (v) => v.category.abbr === "OP" && v.name === "TRADISI_LISAN"
                  );
                  this.selectedSubCategory = findCategory[0];
                  break;
                case "Bab 4.3":
                case "Bab 7.1.3":
                  findCategory = getSubCategoriesResult.data.filter(
                    (v) => v.category.abbr === "OP" && v.name === "ADAT_ISTIADAT"
                  );
                  this.selectedSubCategory = findCategory[0];
                  break;
                case "Bab 4.4":
                case "Bab 7.1.4":
                  findCategory = getSubCategoriesResult.data.filter(
                    (v) => v.category.abbr === "OP" && v.name === "RITUS"
                  );
                  this.selectedSubCategory = findCategory[0];
                  break;
                case "Bab 4.5":
                case "Bab 7.1.5":
                  findCategory = getSubCategoriesResult.data.filter(
                    (v) => v.category.abbr === "OP" && v.name === "PENGETAHUAN_TRADISIONAL"
                  );
                  // console.log(findCategory[0]);
                  this.selectedSubCategory = findCategory[0];
                  break;
                case "Bab 4.6":
                case "Bab 7.1.6":
                  findCategory = getSubCategoriesResult.data.filter(
                    (v) => v.category.abbr === "OP" && v.name === "TEKNOLOGI_TRADISIONAL"
                  );
                  this.selectedSubCategory = findCategory[0];
                  break;
                case "Bab 4.7":
                case "Bab 7.1.7":
                  // console.log("Seni Boi");
                  findCategory = getSubCategoriesResult.data.filter(
                    (v) => v.category.abbr === "OP" && v.name === "SENI"
                  );
                  // console.log(findCategory[0]);
                  this.selectedSubCategory = findCategory[0];
                  break;
                case "Bab 4.8":
                case "Bab 7.1.8":
                  findCategory = getSubCategoriesResult.data.filter(
                    (v) => v.category.abbr === "OP" && v.name === "BAHASA"
                  );
                  this.selectedSubCategory = findCategory[0];
                  break;
                case "Bab 4.9":
                case "Bab 7.1.9":
                  findCategory = getSubCategoriesResult.data.filter(
                    (v) => v.category.abbr === "OP" && v.name === "PERMAINAN_RAKYAT"
                  );
                  this.selectedSubCategory = findCategory[0];
                  break;
                case "Bab 4.10":
                case "Bab 7.1.10":
                  findCategory = getSubCategoriesResult.data.filter(
                    (v) => v.category.abbr === "OP" && v.name === "OLAHRAGA_TRADISIONAL"
                  );
                  this.selectedSubCategory = findCategory[0];
                  break;
                case "Bab 4.11":
                case "Bab 7.1.11":
                  await this.getCategories();
                  findCategory = this.categories.filter((v) => v.abbr === "CB");
                  this.selectedCategory = findCategory[0];
                  break;

                default:
                  break;
              }
              break;
            case "5":
              await this.getCategories();
              this.selectedCategoryLK = this.categories.filter((v) => v.abbr === "LK")[0];
              this.selectedCategoryTB = this.categories.filter((v) => v.abbr === "TB")[0];
              break;
            case "6":
              await this.getCategories();
              this.selectedCategory = this.categories.filter((v) => v.abbr === "SP")[0];
              break;
            default:
              break;
          }
        }
      } catch (error) {
        this.selectedSubCategory = {};
      }
    },

    async getDatas(search = null, page = 1, limit = 10, order = ["createdAt", "desc"], option) {
      this.datas = { loading: true };
      this.totalData = 0;
      this.totalAllData = 0;
      if (!option.subcategoryId && !option.categoryId) {
        this.datas = [];
        return;
      }
      const options = {
        url: apiStore.dapobud.datas,
        params: {
          subcategoryId: option.subcategoryId,
          categoryId: option.categoryId,
          provinceCode: option.provinceCode,
          regencyCode: option.regencyCode,
          order: order[0],
          sort: order[1],
          search: search || null,
          page,
          limit,
        },
      };
      if (option.collectionId) options.params.collectionId = option.collectionId;
      try {
        const getDatas = await fetchWrapper.get(options);
        if (option.TB) {
          this.datasTB = getDatas.data;
          this.totalDataTB = getDatas.totalData;
          this.totalAllDataTB = getDatas.totalAllData;
        } else if (option.LK) {
          this.datasLK = getDatas.data;
          this.totalDataLK = getDatas.totalData;
          this.totalAllDataLK = getDatas.totalAllData;
        } else {
          this.datas = getDatas.data;
          this.totalData = getDatas.totalData;
          this.totalAllData = getDatas.totalAllData;
        }
      } catch (error) {
        if (option.TB) {
          this.datasTB = { error };
        } else if (option.LK) {
          this.datasLK = { error };
        } else {
          this.datas = { error };
        }
      }
    },

    async getAggregationsByCategory(option) {
      this.aggregations = { loading: true };
      if (!option.categoryId) {
        this.aggregations = [];
        return;
      }
      const options = {
        url: apiStore.dapobud.aggregationsByCategory.replace(":dapobudcategoryId", option.categoryId),
        params: {
          provinceCode: option?.provinceCode || null,
          regencyCode: option?.regencyCode || null,
        },
      };
      try {
        const getAggResult = await fetchWrapper.get(options);
        this.aggregations = getAggResult.data;
      } catch (error) {
        this.aggregations = { error };
      }
    },

    async getAggregationsBySubCategory(option) {
      this.aggregations = { loading: true };
      if (!option.subcategoryId) {
        this.aggregations = [];
        return;
      }
      const options = {
        url: apiStore.dapobud.aggregationsBySubCategory.replace(":dapobudSubCategoryId", option.subcategoryId),
        params: {
          provinceCode: option?.provinceCode || null,
          regencyCode: option?.regencyCode || null,
        },
      };
      try {
        const getAggResult = await fetchWrapper.get(options);
        this.aggregations = getAggResult.data;
      } catch (error) {
        this.aggregations = { error };
      }
    },
  },
});
