import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '@/stores';
import apiStore from './api.store';

export const useComposerPapperExperienceStore = defineStore({
    id: 'composerPapperExperience',
    state: () => ({
        composerPapperExperiences: {},
        composerPapperExperience: {},
        totalData: 0,
        totalAllData: 0,
    }),
    actions: {
        async getPapperExperiences(id) {
            this.composerPapperExperiences = { loading: true };
            this.composerPapperExperience = {};
            this.totalData = 0;
            this.totalAllData = 0;
            const newUrl = apiStore.composer.composerPapperExperiences.replace(":composerId", `${id}`);
            const options = {
                url: newUrl,
                params: {
                    page:1,
                    limit:20,
                }
            };
            try {
                const getComposersResult = await fetchWrapper.get(options);   
                this.composerPapperExperiences = getComposersResult.data;   
            } catch (error) {
                this.composerPapperExperiences = { error };
            }
        },
        async getPapperExperience(id, papperId) {
            this.composerPapperExperience = { loading: true };
            let newUrl = apiStore.composer.composerPapperExperience.replace(":composerId", `${id}`).replace(":papperId", `${papperId}`);
            const options = {
                url: newUrl,
            };
            try {
                const getComposerEducationResult = await fetchWrapper.get(options);    
                this.composerPapperExperience = getComposerEducationResult.data;
            } catch (error) {
                this.composerPapperExperience = { error };
            }
        },
        async createComposerPapperExperience(payload, id) {
            const alertStore = useAlertStore();
            let newUrl = apiStore.composer.composerPapperExperienceCreate.replace(":composerId", `${id}`);
            payload.year = parseInt(payload.year);
           
            const options = {
                url: newUrl,
                body: payload,
            };
            try {
                await fetchWrapper.post(options);
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async updateComposerPapperExperience(payload, id, papperId) {
            const alertStore = useAlertStore();
            let newUrl = apiStore.composer.composerPapperExperiencePatch.replace(":composerId", `${id}`).replace(`:papperId`, papperId);
            payload.year = parseInt(payload.year);
           
            const options = {
                url: newUrl,
                body: payload,
            };
            try {
                await fetchWrapper.patch(options); 
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async delete(id, papperId) {
            const index = this.composerPapperExperiences.findIndex(x => x.id === papperId);
            if (index > -1) this.composerPapperExperiences[index].isDeleting = true;
            const alertStore = useAlertStore();
            let newUrl = apiStore.composer.composerPapperExperienceDelete.replace(":composerId", `${id}`).replace(":papperId", `${papperId}`);
            const options = {
                url: newUrl,
            };
            try {
                await fetchWrapper.delete(options);
                this.composerPapperExperiences = this.composerPapperExperiences.filter(x => x.id !== papperId);
                alertStore.success('Data berhasil dihapus');  
            } catch (error) {
                this.composerPapperExperiences[index].isDeleting = false;
                console.log(`error`, error);
                alertStore.error(error);
            }
        },
    }
});
