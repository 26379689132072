import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '@/stores';
import apiStore from './api.store';

export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        users: {},
        user: {},
        roleUsers: [],
        roleChecked: [],
        selectedRoleProvinces: [],
        selectedRoleRegencies: [],
        hasRegion: false,
        totalData: 0,
        totalAllData: 0,
    }),
    actions: {
        async getUsers(search = null, page = 1, limit = 10, order = ['createdAt', 'desc']) {
            this.users = { loading: true };
            this.totalData = 0;
            this.totalAllData = 0;
            const options = {
                url: apiStore.user.users,
                params: {
                    order: order[0],
                    sort: order[1],
                    search: search || null,
                    page,
                    limit,
                }
            };
            try {
                const getUsersResult = await fetchWrapper.get(options);   
                this.totalData = getUsersResult.totalData;
                this.totalAllData = getUsersResult.totalAllData;
                this.users = getUsersResult.data;   
            } catch (error) {
                this.users = { error };
            }
        },
        async getUser(id) {
            this.user = { loading: true };
            this.roleUsers = [];
            this.roleChecked = [];
            this.selectedRoleProvinces = [];
            this.selectedRoleRegencies = [];
            this.hasRegion = false;
            const newUrl = apiStore.user.user.replace(":userId", `${id}`);
            const options = {
                url: newUrl,
            };
            try {
                const getUserResult = await fetchWrapper.get(options);    
                this.user = getUserResult.data;
                this.user.roleUsers.forEach((v, i)  => {
                    let hasProvince = false;
                    let hasRegency = false;
                    if(v.role.hasProvince) hasProvince = true;
                    if(v.role.hasRegency) hasRegency = true;

                    this.roleUsers.push(v.role.id);
                    this.roleChecked.push({id: v.role.id, hasRegion: v.role.hasRegion, hasProvince, hasRegency});
                    if (v.province) this.selectedRoleProvinces[v.role.id] = (v.province.code);
                    if (v.regency) this.selectedRoleRegencies[v.role.id] = (v.regency.code);
                    if(v.role.hasRegion === true) this.hasRegion = true; 
                });
            } catch (error) {
                this.user = { error };
            }
        },
        async createUser(payload) {
            const alertStore = useAlertStore();
            const options = {
                url: apiStore.user.userCreate,
                body: payload
            };
            try {
                await fetchWrapper.post(options);
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async updateUser(id, payload) {
            const alertStore = useAlertStore();
            let roleUsersSelected = [];
            // console.log(payload);
            if(payload.role && payload.role.length > 0) {
                payload.role.forEach((v) => {
                    if(payload.regencyCode && payload.regencyCode[v]) {
                        roleUsersSelected.push({
                            roleId: v,
                            regencyCode: payload.regencyCode[v]
                        })
                    }
                    if(payload.provinceCode && payload.provinceCode[v]) {
                        roleUsersSelected.push({
                            roleId: v,
                            provinceCode: payload.provinceCode[v]
                        })
                    }
                });
            }
            const newPayload = {
                isActive: true,
                roleUsers: roleUsersSelected
            }
            // console.log(newPayload);
            const newUrl = apiStore.user.userPatch.replace(":userId", `${id}`);
            const options = {
                url: newUrl,
                body: newPayload
            };
            try {
                await fetchWrapper.patch(options); 
                alertStore.success();  
            } catch (error) {
                console.log(error);
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async deleteUser(id) {
            const index = this.users.findIndex(x => x.id === id);
            if (index > -1) this.users[index].isDeleting = true;
            const alertStore = useAlertStore();
            const newUrl = apiStore.user.userDelete.replace(":userId", `${id}`);
            const options = {
                url: newUrl,
            };
            try {
                await fetchWrapper.delete(options);
                this.users = this.users.filter(x => x.id !== id);
                alertStore.success('Data berhasil dihapus');  
            } catch (error) {
                this.users[index].isDeleting = false;
                console.log(`error`, error);
                alertStore.error(error);
            }
        },
    }
});
