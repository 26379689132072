import permission  from "@/stores/permission.store";

export default {
  path: '/problem',
  component: () => import("@/views/problems/Layout.vue"),
  children: [
      { path: '', 
        meta: {
          iconActive: 'Problem',
          permission: permission.problem.read,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Permasalahan dan Rekomendasi',
                }
            ]
        },
        component: () => import("@/views/problems/List.vue") 
      },
      { path: 'view/:id', 
        meta: {
          iconActive: 'Problem',
          permission: permission.problem.read,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Permasalahan dan Rekomendasi',
                    link: '/problem'
                },
                {
                  name: 'Daftar Permasalahan dan Rekomendasi',
              },
            ]
        },
        component: () => import("@/views/problems/View.vue") 
      },
  ]
};
