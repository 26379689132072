import permission from "@/stores/permission.store";
import { useRoute } from "vue-router";

const routes = [
  {
    path: "manuskrip",
    title: "Manuskrip",
    subCategory: "MANUSKRIP",
  },
  {
    path: "adat-istiadat",
    title: "Adat Istiadat",
    subCategory: "ADAT_ISTIADAT",
  },
  {
    path: "ritus",
    title: "Ritus",
    subCategory: "RITUS",
  },
  {
    path: "pengetahuan-tradisional",
    title: "Pengetahuan Tradisional",
    subCategory: "PENGETAHUAN_TRADISIONAL",
  },
  {
    path: "teknologi-tradisional",
    title: "Teknologi Tradisional",
    subCategory: "TEKNOLOGI_TRADISIONAL",
  },
  {
    path: "seni",
    title: "Seni",
    subCategory: "SENI",
  },
  {
    path: "tradisi-lisan",
    title: "Tradisi Lisan",
    subCategory: "TRADISI_LISAN",
  },
  {
    path: "bahasa",
    title: "Bahasa",
    subCategory: "BAHASA",
  },
  {
    path: "permainan-rakyat",
    title: "Permainan Rakyat",
    subCategory: "PERMAINAN_RAKYAT",
  },
  {
    path: "olahraga-tradisional",
    title: "Olahraga Tradisional",
    subCategory: "OLAHRAGA_TRADISIONAL",
  },
  {
    path: "cagar-budaya",
    title: "Cagar Budaya",
    category: "CB",
  },
];

export default {
  path: "/dapobud",
  component: () => import("@/views/dapobud/Layout.vue"),
  children: routes.map(({ path, title, category, subCategory }) => ({
    path,
    component: () => import("@/views/dapobud/MasterPage.vue"),
    meta: {
      iconActive: "dapobud",
      title,
      permission: permission.ppkd.create,
      category,
      subCategory,
      breadcrumb: [
        {
          name: "Dashboard",
          link: "/",
        },
        {
          name: title,
        },
      ],
    },
  })),
};
