import permission from "@/stores/permission.store";

export default {
  path: '/users',
  component: () => import("@/views/users/Layout.vue"),
  children: [
    {
      path: '',
      meta: {
        iconActive: 'Pengguna',
        permission: permission.user.read,
        breadcrumb: [
          {
            name: 'Dashboard',
            link: '/',
          },
          {
            name: 'Pengguna',
          }
        ]
      },
      component: () => import("@/views/users/List.vue")
    },
    {
      path: 'add',
      meta: {
        iconActive: 'Pengguna',
        permission: permission.user.create,
        breadcrumb: [
          {
            name: 'Dashboard',
            link: '/',
          },
          {
            name: 'Pengguna',
            link: '/users',
          },
          {
            name: 'Tambah Pengguna',
          },
        ]
      },
      component: () => import("@/views/users/Form.vue")
    },
    {
      path: 'edit/:id',
      meta: {
        iconActive: 'Pengguna',
        permission: permission.user.update,
        breadcrumb: [
          {
            name: 'Dashboard',
            link: '/',
          },
          {
            name: 'Pengguna',
            link: '/users'
          },
          {
            name: 'Ubah Pengguna',
          },
        ]
      },
      component: () => import("@/views/users/Form.vue")
    },
    {
      path: 'profil-region',
      meta: {
        iconActive: 'Pengguna',
        permission: permission.blog.create,
        breadcrumb: [
          {
            name: 'Dashboard',
            link: '/',
          },
          {
            name: 'Atur Profile Budaya',
          },
        ]
      },
      component: () => import("@/views/users/FormProfileRegion.vue")
    },
  ]
};
