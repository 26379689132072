import permission  from "@/stores/permission.store";

export default {
  path: '/profil-tim-penyusun',
  component: () => import("@/views/composers/Layout.vue"),
  children: [
      { path: '', 
        meta: {
          iconActive: 'profilTimPenyusun',
          permission: permission.composer.read,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Profil Tim Penyusun',
                }
            ]
        },
        component: () => import("@/views/composers/List.vue") 
      },
      { path: 'add', 
        meta: {
          iconActive: 'profilTimPenyusun',
          permission: permission.composer.create,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Profil Tim Penyusun',
                    link: '/profil-tim-penyusun',
                },
                {
                  name: 'Tambah Profil Tim Penyusun',
                },
            ]
        },
        component: () => import("@/views/composers/Form.vue") 
      },
      { path: 'edit/:id', 
        meta: {
          iconActive: 'profilTimPenyusun',
          permission: permission.composer.update,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Profil Tim Penyusun',
                    link: '/profil-tim-penyusun'
                },
                {
                  name: 'Ubah Profil Tim Penyusun',
              },
            ]
        },
        component: () => import("@/views/composers/Form.vue") 
      },
  ]
};
