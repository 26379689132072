import permission from "@/stores/permission.store";
import { useRoute } from "vue-router";

export default {
  path: "/dokumen",
  component: () => import("@/views/documents/Layout.vue"),
  children: [
    {
      path: "",
      meta: {
        iconActive: "dokumen",
        permission: permission.ppkd.read,
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/",
          },
          {
            name: "Dokumen",
          },
        ],
      },
      component: () => import("@/views/documents/List.vue"),
    },
    {
      path: "add",
      meta: {
        iconActive: "dokumen",
        permission: permission.ppkd.create,
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/",
          },
          {
            name: "Dokumen",
            link: "/dokumen",
          },
          {
            name: "Tambah Dokumen",
          },
        ],
      },
      component: () => import("@/views/documents/sections/Form.vue"),
    },
    {
      path: "/dokumen/:id/content/:slug/add",
      meta: {
        iconActive: "dokumen",
        permission: permission.ppkd.create,
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/",
          },
          {
            name: "Dokumen",
            link: "/dokumen",
          },
          {
            name: "Buat Dokumen",
          },
        ],
      },
      component: () => import("@/views/documents/sections/FormCreate.vue"),
    },
    {
      path: "edit/:id",
      meta: {
        iconActive: "dokumen",
        permission: permission.ppkd.update,
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/",
          },
          {
            name: "Dokumen",
            link: "/dokumen",
          },
          {
            name: "Ubah Dokumen",
          },
        ],
      },
      component: () => import("@/views/documents/sections/Edit.vue"),
    },
    {
      path: "verify/:id",
      meta: {
        iconActive: "dokumen",
        permission: permission.ppkd.update,
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/",
          },
          {
            name: "Dokumen",
            link: "/dokumen",
          },
          {
            name: "Pengesahan Dokumen",
          },
        ],
      },
      component: () => import("@/views/documents/sections/Verify.vue"),
    },
    {
      path: "process/:id",
      meta: {
        iconActive: "dokumen",
        permission: permission.ppkd.update,
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/",
          },
          {
            name: "Dokumen",
            link: "/dokumen",
          },
          {
            name: "Pengesahan Dokumen PPKD Kabupaten",
          },
        ],
      },
      component: () => import("@/views/documents/sections/FormVerify.vue"),
    },
    {
      path: "upgrade/:id",
      meta: {
        iconActive: "dokumen",
        permission: permission.ppkd.update,
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/",
          },
          {
            name: "Dokumen",
            link: "/dokumen",
          },
          {
            name: "Pemutakhiran Dokumen PPKD",
          },
        ],
      },
      component: () => import("@/views/documents/sections/FormUpgrade.vue"),
    },
    {
      path: "verified/:id",
      meta: {
        iconActive: "dokumen",
        permission: permission.ppkd.update,
        breadcrumb: [
          {
            name: "Dashboard",
            link: "/",
          },
          {
            name: "Dokumen",
            link: "/dokumen",
          },
          {
            name: "Lihat Dokumen PPKD Kabupaten",
          },
        ],
      },
      component: () => import("@/views/documents/sections/Verified.vue"),
    },
  ],
};
