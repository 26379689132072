import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore, useRegionStore, useAuthStore, useDocumentStore } from '@/stores';
import apiStore from './api.store';
import { useRoute } from "vue-router";

export const useRegulationStore = defineStore({
    id: 'regulation',
    state: () => ({
        regulations: {},
        regulation: {},
        totalData: 0,
        totalAllData: 0,
    }),
    actions: {
        async getRegulations(search = null, page = 1, limit = 10, order = ['createdAt', 'desc']) {
            const document = useDocumentStore();
            this.regulations = { loading: true };
            this.regulation = {};
            this.totalData = 0;
            this.totalAllData = 0;
            const options = {
                url: apiStore.regulation.regulations,
                params: {
                    order: order[0],
                    sort: order[1],
                    search: search || null,
                    page,
                    limit,
                }
            };
            try {
                const route = useRoute();
                if(route?.path == '/peraturan-daerah') {
                    document.document = {};
                }
                if(document?.document.id) {
                    options.params.provinceCode = document.document?.province?.code || null;
                    options.params.regencyCode = document.document?.regency?.code || null;
                }
                const getRegulationsResult = await fetchWrapper.get(options);   
                this.totalData = getRegulationsResult.totalData;
                this.totalAllData = getRegulationsResult.totalAllData;
                this.regulations = getRegulationsResult.data;   
            } catch (error) {
                this.regulations = { error };
            }
        },
        async getRegulation(id) {
            const region  = useRegionStore();
            this.regulation = { loading: true };
            const newUrl = apiStore.regulation.regulation.replace(":regionalRegulationId", `${id}`);
            const options = {
                url: newUrl,
            };
            try {
                const getRegulationResult = await fetchWrapper.get(options);   
                if (getRegulationResult.data.province) {
                    region.selectedProvince = getRegulationResult.data.province.code;
                    await region.filterRegencies(region.selectedProvince);
                }
                if (getRegulationResult.data.regency) {
                    region.selectedRegency = getRegulationResult.data.regency.code;
                    let provinceCode = getRegulationResult.data.regency.code.split(".");
                    if (region.selectedProvince == null) await region.filterRegencies(provinceCode[0]);
                } 
                this.regulation = getRegulationResult.data;
            } catch (error) {
                this.regulation = { error };
            }
        },
        async createRegulation(payload) {
            const alertStore = useAlertStore();
            const options = {
                url: apiStore.regulation.regulationCreate,
                body: payload,
                isMultipart: true,
            };
            try {
                await fetchWrapper.post(options);
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async updateRegulation(id, payload) {
            const authStore = useAuthStore();
            const alertStore = useAlertStore();
            if(payload.file instanceof File === false) delete payload.file;
            if (authStore.user.regencyCode) payload.regencyCode = authStore.user.regencyCode;
            if (authStore.user.provinceCode) payload.provinceCode = authStore.user.provinceCode;
            const newUrl = apiStore.regulation.regulationPatch.replace(":regionalRegulationId", `${id}`);
            const options = {
                url: newUrl,
                body: payload,
                isMultipart: true,
            };
            try {
                await fetchWrapper.patch(options); 
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async deleteRegulation(id, search=null, page=null, limit=null, order = ['createdAt', 'desc']) {
            const index = this.regulations.findIndex(x => x.id === id);
            if (index > -1) this.regulations[index].isDeleting = true;
            const alertStore = useAlertStore();
            const newUrl = apiStore.regulation.regulationDelete.replace(":regionalRegulationId", `${id}`);
            const options = {
                url: newUrl,
            };
            try {
                await fetchWrapper.delete(options);
                await this.getRegulations(search, page, limit, order);
                alertStore.success('Data berhasil dihapus');  
            } catch (error) {
                console.log(`error`, error);
                this.regulations[index].isDeleting = false;
                alertStore.error(error);
            }
        },
    }
});
