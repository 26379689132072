import { defineStore } from 'pinia';

export const useAlertStore = defineStore({
    id: 'alert',
    state: () => ({
        alert: null,
        swal: false
    }),
    actions: {
        success(message = null) {
            if (!message) message = 'Data berhasil disimpan';
            this.alert = { message, type: 'alert-success' };
            setTimeout(() => {
                this.clear();
            }, 2000);
        },
        error(error = null) {
            let message = 'Terjadi kesalahan pada server';
            if(error) message = error;
            if(error && error.response && error.response.data && error.response.data.message) message = error.response.data.message;
            this.alert = { message, type: 'alert-danger' };
            setTimeout(() => {
                this.clear();
            }, 2000);
        },
        clear() {
            this.alert = null;
        },
        delete() {
            this.swal = true;
        }
    }
});
