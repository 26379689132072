import { defineStore } from "pinia";
import { fetchWrapper } from "@/helpers";
import { useAlertStore, useFileStore, useRegionStore } from "@/stores";
import apiStore from "./api.store";
import { router } from "@/router";

export const useDocumentStore = defineStore({
  id: "document",
  state: () => ({
    documents: {},
    document: {},
    documentContent: {},
    documentContents: {},
    categories: new Array(),
    categoriesWithStatuses: [],
    documentCategories: {},
    totalData: 0,
    totalAllData: 0,
  }),
  actions: {
    async getCategories(search = null, page = 1, limit = 100, order = ["level", "asc"], level = null) {
      this.categories = { loading: true };
      this.totalData = 0;
      this.totalAllData = 0;
      const options = {
        url: apiStore.document.categories,
        params: {
          order: order[0],
          sort: order[1],
          search: search || null,
          page,
          limit,
          level,
        },
      };
      try {
        const getCategoriesResult = await fetchWrapper.get(options);
        const sideBar = [];
        this.documentCategories = getCategoriesResult.data;
        getCategoriesResult.data.forEach((v, i) => {
          if (v.parent == null) sideBar.push(v);
          if (v.parent) {
            let indexLevel1 = sideBar.findIndex((w) => w.chapter === v.chapter);
            if (indexLevel1 >= 0) {
              if (!sideBar[indexLevel1].child) sideBar[indexLevel1].child = [];
              sideBar[indexLevel1].child.push(v);
            }
          }
          // if (v.parent && v.level === 3) {
          //     sideBar.forEach((a) => {
          //         if(a.child) {
          //             let indexLevel2 = a.child.findIndex(i => i.id === v.parent.id && i.chapter === v.chapter);
          //             if (indexLevel2 >= 0) {
          //                 if(!a.child[indexLevel2].child) a.child[indexLevel2].child = [];
          //                 a.child[indexLevel2].child.push(v);
          //             }
          //         }
          //     });
          // }
          // if (v.parent && v.level === 4) {
          //     sideBar.forEach((a) => {
          //         if(a.child) {
          //             a.child.forEach((b) => {
          //                 if(b.child) {
          //                     let indexLevel3 = b.child.findIndex(i => i.id === v.parent.id && i.chapter === v.chapter);
          //                     if (indexLevel3 >= 0) {
          //                         if(!b.child[indexLevel3].child) b.child[indexLevel3].child = [];
          //                         b.child[indexLevel3].child.push(v);
          //                     }
          //                 }
          //             });
          //         }
          //     });
          // }
        });
        this.categories = sideBar;
        // this.categories = getCategoriesResult.data;
      } catch (error) {
        this.categories = { error };
      }
    },

    async getDocumentStatus(id) {
      this.categoriesWithStatuses = { loading: true };
      this.totalData = 0;
      this.totalAllData = 0;
      const options = {
        url: apiStore.document.readStatus.replace(":ppkdDocumentId", `${id}`),
      };
      try {
        const getCategoriesResult = await fetchWrapper.get(options);
        const sideBar = [];
        this.categoriesWithStatuses = getCategoriesResult.data;
        getCategoriesResult.data.forEach((v, i) => {
          if (v.parent == null) sideBar.push(v);
          if (v.parent) {
            let indexLevel1 = sideBar.findIndex((w) => w.chapter === v.chapter);
            if (indexLevel1 >= 0) {
              if (!sideBar[indexLevel1].child) sideBar[indexLevel1].child = [];
              sideBar[indexLevel1].child.push(v);
            }
          }
        });
        this.categories = sideBar;
        // this.categories = getCategoriesResult.data;
      } catch (error) {
        this.categories = { error };
      }
    },

    async getDocuments(search = null, page = 1, limit = 10, order = ["createdAt", "desc"], filterOpt = {}) {
      this.documents = { loading: true };
      this.document = {};
      this.totalData = 0;
      this.totalAllData = 0;
      const options = {
        url: apiStore.document.documents,
        params: {
          order: order[0],
          sort: order[1],
          search: search || null,
          page,
          limit,
        },
      };
      if (filterOpt.provinceCode) options.params.provinceCode = filterOpt.provinceCode;
      if (filterOpt.regencyCode) options.params.regencyCode = filterOpt.regencyCode;
      if (filterOpt.status) options.params.status = filterOpt.status;
      if (filterOpt.withDetermination) options.params.withDetermination = filterOpt.withDetermination;
      if (router.currentRoute.value.path === "/problem") options.params.status = "disahkan";
      try {
        // await this.getCategories();
        const getDocumentsResult = await fetchWrapper.get(options);
        this.totalData = getDocumentsResult.totalData;
        this.totalAllData = getDocumentsResult.totalAllData;
        this.documents = getDocumentsResult.data;
      } catch (error) {
        this.documents = { error };
      }
    },
    async getDocument(id) {
      const region = useRegionStore();
      this.document = { loading: true };
      const newUrl = apiStore.document.document.replace(":ppkdDocumentId", `${id}`);
      const options = {
        url: newUrl,
      };
      try {
        const getDocumentResult = await fetchWrapper.get(options);
        if (getDocumentResult.data.province) {
          region.selectedProvince = getDocumentResult.data.province.code;
          await region.filterRegencies(region.selectedProvince);
        }
        if (getDocumentResult.data.regency) {
          region.selectedRegency = getDocumentResult.data.regency.code;
          let provinceCode = getDocumentResult.data.regency.code.split(".");
          if (region.selectedProvince == null) await region.filterRegencies(provinceCode[0]);
        }
        this.document = getDocumentResult.data;
      } catch (error) {
        this.document = { error };
      }
    },
    async createDocument(payload) {
      const alertStore = useAlertStore();
      const options = {
        url: apiStore.document.documentCreate,
        body: payload,
      };
      try {
        const createDocument = await fetchWrapper.post(options);
        router.push(`/dokumen/${createDocument.data.id}/content/sampul-dokumen/add`);
        // await this.createDocumentContent(createDocument.data.id, sampulDokumenPayload);
        this.document = createDocument.data;
        alertStore.success();
      } catch (error) {
        console.log(`error`, error);
        alertStore.error(error);
        throw error;
      }
    },
    async updateDocument(id, payload) {
      const alertStore = useAlertStore();
      const newUrl = apiStore.document.documentPatch.replace(":ppkdDocumentId", `${id}`);
      const options = {
        url: newUrl,
        body: {
          year: parseInt(payload.year),
          provinceCode: payload?.province?.code,
          regencyCode: payload?.regency?.code,
        },
      };
      try {
        const patchDocument = await fetchWrapper.patch(options);
        await this.getDocument(id);
        alertStore.success();
      } catch (error) {
        console.log(`error`, error);
        alertStore.error(error);
        throw error;
      }
    },
    async deleteDocument(id, search = null, page = null, limit = null, order = ["createdAt", "desc"]) {
      const index = this.documents.findIndex((x) => x.id === id);
      if (index > -1) this.documents[index].isDeleting = true;
      const alertStore = useAlertStore();
      const newUrl = apiStore.document.documentDelete.replace(":ppkdDocumentId", `${id}`);
      const options = {
        url: newUrl,
      };
      try {
        await fetchWrapper.delete(options);
        await this.getDocuments(search, page, limit, order);
        alertStore.success("Data berhasil dihapus");
      } catch (error) {
        console.log(`error`, error);
        this.documents[index].isDeleting = false;
        alertStore.error(error);
      }
    },
    async createDocumentContent(id, payload) {
      const alertStore = useAlertStore();
      const fileStore = useFileStore();

      let sampulDokumenPayload = null;
      let documentDeterminationPayload = null;

      if (payload.file instanceof File === true && payload.slug === "sampul-dokumen") {
        sampulDokumenPayload = {
          // category: payload.slug,
          cover: payload.file,
        };
      }

      if (payload.slug === "bab-8.1-surat-keputusan-kepala-daerah-tim-penyusun-ppkd") {
        documentDeterminationPayload = {
          determinationNumber: payload.determinationNumber,
          type: "SK Tim Penyusun",
          verifyDate: payload.verifyDate,
        };
        if (payload.file instanceof File === true) documentDeterminationPayload.file = payload.file;
      }

      if (payload.slug === "bab-8.2-surat-keputusan-tim-penyusun-ppkd-tentang-pemilihan-koordinator") {
        documentDeterminationPayload = {
          determinationNumber: payload.determinationNumber,
          type: "SK Tim Penyusun PPKD tentang Pemilihan koordinator",
          verifyDate: payload.verifyDate,
        };
        if (payload.file instanceof File === true) documentDeterminationPayload.file = payload.file;
      }

      let newPayload = { content: payload.content };
      // contentSubmitBySlug
      const options = {
        url: apiStore.documentContent.contentSubmitBySlug
          .replace(":ppkdDocumentId", `${id}`)
          .replace(":slug", payload.slug),
        body: newPayload,
      };

      if (sampulDokumenPayload) {
        options.body = sampulDokumenPayload;
        options.isMultipart = true;
      }

      if (documentDeterminationPayload) {
        options.body = documentDeterminationPayload;
        options.isMultipart = true;
        options.url = apiStore.documentDetermination.documentDeterminationCreate.replace(":ppkdDocumentId", `${id}`);
        try {
          if (!this.document.determinationId) {
            await fetchWrapper.post(options);
          } else {
            options.url = apiStore.documentDetermination.documentDeterminationPatch
              .replace(":ppkdDocumentId", `${id}`)
              .replace(":ppkdDocumentDeterminationId", `${this.document.determinationId}`);
            await fetchWrapper.patch(options);
          }
          alertStore.success();
          await this.nextPage(payload.slug, id);
        } catch (error) {
          alertStore.error(error);
          await this.nextPage(payload.slug, id);
        }
        return;
      }

      try {
        const createDocument = await fetchWrapper.patch(options);
        this.document = createDocument.data;
        alertStore.success();
        await this.nextPage(payload.slug, id);
      } catch (error) {
        console.log(`error`, error);
        alertStore.error(error);
        await this.nextPage(payload.slug, id);
      }
      // this.nextPage(payload.slug, id);
    },

    async getDocumentContent(id, slug) {
      this.documentContent = { loading: true };
      const newUrl = apiStore.documentContent.contentBySlug
        .replace(":ppkdDocumentId", `${id}`)
        .replace(":slug", `${slug}`);
      const options = {
        url: newUrl,
      };
      try {
        const getDocumentContentResult = await fetchWrapper.get(options);
        this.documentContent = getDocumentContentResult.data;
        if (this.documentContent.category.tag == "Bab 8.1") await this.getDocumentDetermination(id, `SK Tim Penyusun`);
        if (this.documentContent.category.tag == "Bab 8.2")
          await this.getDocumentDetermination(id, `SK Tim Penyusun PPKD tentang Pemilihan koordinator`);
      } catch (error) {
        this.documentContent = { error };
      }
    },

    // async getDocumentContent(id, slug) {
    //     this.documentContent = { loading: true };
    //     const newUrl = apiStore.documentContent.contentBySlug.replace(":ppkdDocumentId", `${id}`).replace(":slug", `${slug}`);
    //     const options = {
    //         url: newUrl,
    //     };
    //     try {
    //         const getDocumentContentResult = await fetchWrapper.get(options);
    //         this.documentContent = getDocumentContentResult.data;
    //     } catch (error) {
    //         this.documentContent = { error };
    //     }
    // },

    async getDocumentDetermination(ppkdDocumentId, type) {
      const newUrl = apiStore.documentDetermination.documentDeterminations.replace(
        ":ppkdDocumentId",
        `${ppkdDocumentId}`
      );
      const options = {
        url: newUrl,
        params: {
          type,
        },
      };
      try {
        const getDeterminationResult = await fetchWrapper.get(options);
        const determinationData = getDeterminationResult.data.length > 0 ? getDeterminationResult.data[0] : null;
        if (determinationData) {
          this.document.verifyDate = determinationData.verifyDate;
          this.document.file = determinationData.file;
          this.document.determinationNumber = determinationData.determinationNumber;
          this.document.determinationId = determinationData.id;
        }
      } catch (error) {
        this.documentContent = { error };
      }
    },

    async getAllDocumentContent(id) {
      this.documentContents = { loading: true };
      const newUrl = apiStore.documentContent.contents.replace(":ppkdDocumentId", `${id}`);
      const options = {
        url: newUrl,
        params: {
          page: 1,
          limit: 100,
        },
      };
      try {
        const getDocumentContentResult = await fetchWrapper.get(options);
        this.documentContents = getDocumentContentResult.data;
        getDocumentContentResult.data.forEach((v, i) => {
          let indexCategories = this.categories.findIndex((w) => w.chapter === v.category.chapter);
          if (indexCategories > -1) {
            if (!this.categories[indexCategories].total) this.categories[indexCategories].total = 0;
            if (!this.categories[indexCategories].done) this.categories[indexCategories].done = 0;
            if (v.content != null) this.categories[indexCategories].done += 1;
            this.categories[indexCategories].total += 1;
          }
        });
        console.log(this.categories);
      } catch (error) {
        this.documentContents = { error };
      }
    },

    async verification(id, payload) {
      const alertStore = useAlertStore();
      if (payload.document instanceof File === false) delete payload.document;
      if (payload.verifyFile instanceof File === false) delete payload.verifyFile;
      const newUrl = apiStore.document.verification.replace(":ppkdDocumentId", `${id}`);
      const options = {
        url: newUrl,
        body: payload,
        isMultipart: true,
      };
      try {
        const patchDocument = await fetchWrapper.patch(options);
        alertStore.success();
        router.push(`/dokumen/verified/${id}`); 
      } catch (error) {
        console.log(`error`, error);
        alertStore.error(error);
        throw error;
      }
    },

    async presentVerification(id) {
      const alertStore = useAlertStore();
      const newUrl = apiStore.document.presentVerification.replace(":ppkdDocumentId", `${id}`);
      const options = {
        url: newUrl,
      };
      try {
        await fetchWrapper.patch(options);
        alertStore.success();
      } catch (error) {
        console.log(`error`, error);
        alertStore.error(error);
        throw error;
      }
    },

    async cancelPresentVerification(id) {
      const alertStore = useAlertStore();
      const newUrl = apiStore.document.cancelPresentVerification.replace(
        ":ppkdDocumentId",
        `${id}`
      );
      const options = {
        url: newUrl,
      };
      try {
        await fetchWrapper.patch(options);
        alertStore.success();
      } catch (error) {
        console.log(`error`, error);
        alertStore.error(error);
        throw error;
      }
    },

    async upgrade(id) {
      const alertStore = useAlertStore();
      const newUrl = apiStore.document.upgrade.replace(":ppkdDocumentId", `${id}`);
      const options = {
        url: newUrl,
      };
      try {
        await fetchWrapper.post(options);
        alertStore.success();
      } catch (error) {
        console.log(`error`, error);
        alertStore.error(error);
        throw error;
      }
    },

    async saveByteArray(reportName, byte) {
      var blob = new Blob([byte], {type: "application/doc"});
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      var fileName = reportName;
      link.download = fileName;
      link.click();
    },

    async download(id) {
      const alertStore = useAlertStore();
      const newUrl = apiStore.document.download.replace(":ppkdDocumentId", `${id}`);
      const options = {
        url: newUrl,
        responseType: 'arraybuffer',
      };
      try {
        const download = await fetchWrapper.get(options);
        await this.saveByteArray(`${this.document.title}-${this.document.year}-versi-${this.document.version}.docx`, download);
        alertStore.success();
      } catch (error) {
        console.log(`error`, error);
        alertStore.error(error);
        throw error;
      }
    },

    async nextPage(slug, id) {
      // penyesuain blogs -> lihat figma
      //bab-2.2.1-tim-penyusun
      //bab-3.1-lembaga-pendidikan-menengah-bidang-kebudayaan
      //bab-3.2-lembaga-pendidikan-tinggi-bidang-kebudayaan
      // 4, 5, 6
      //Bab 7.1 Permasalahan Dan Rekomendasi
      //Bab 7.1.1 Permasalahan Dan Rekomendasi Manuskrip
      //Bab 8.1 Surat Keputusan Tim Penyusun Ppkd
      //bab-8.2-surat-keputusan-tim-penyusun-ppkd-tentang-pemilihan-koordinator
      //bab- 8.3 - 8.7-peraturan-peraturan-yang-berkaitan-dengan-kebudayaan
      //// aktif dihilangkan
      //can verify diajukan
      //can upgrade pemutakhiran
      //is required ada isian
      // key status false true

      switch (slug) {
        case "sampul-dokumen":
          router.push(`/dokumen/${id}/content/kata-pengantar/add`);
          break;
        case "kata-pengantar":
          router.push(`/dokumen/${id}/content/daftar-isi/add`);
          break;
        case "daftar-isi":
          router.push(`/dokumen/${id}/content/bab-1-rangkuman-umum/add`);
          break;
        case "bab-1-rangkuman-umum":
          router.push(`/dokumen/${id}/content/bab-2-profil-daerah/add`);
          break;
        case "bab-2-profil-daerah":
          router.push(`/dokumen/${id}/content/bab-2.1-tentang-daerah/add`);
          break;
        case "bab-2.1-tentang-daerah":
          router.push(`/dokumen/${id}/content/bab-2.1.1-wilayah-dan-karakteristik-alam/add`);
          break;
        case "bab-2.1.1-wilayah-dan-karakteristik-alam":
          router.push(`/dokumen/${id}/content/bab-2.1.2-demografi/add`);
          break;
        case "bab-2.1.2-demografi":
          router.push(`/dokumen/${id}/content/bab-2.1.3-latar-belakang-corak-budaya/add`);
          break;
        case "bab-2.1.3-latar-belakang-corak-budaya":
          router.push(`/dokumen/${id}/content/bab-2.1.3.1-corak-budaya/add`);
          break;
        case "bab-2.1.3.1-corak-budaya":
          router.push(`/dokumen/${id}/content/bab-2.1.3.2-keragaman-budaya/add`);
          break;
        case "bab-2.1.3.2-keragaman-budaya":
          router.push(`/dokumen/${id}/content/bab-2.1.4-sejarah/add`);
          break;
        case "bab-2.1.4-sejarah":
          router.push(`/dokumen/${id}/content/bab-2.1.4.1-sejarah-singkat-budaya/add`);
          break;
        case "bab-2.1.4.1-sejarah-singkat-budaya":
          router.push(`/dokumen/${id}/content/bab-2.1.4.2-sejarah-singkat-wilayah-administratif/add`);
          break;
        case "bab-2.1.4.2-sejarah-singkat-wilayah-administratif":
          router.push(`/dokumen/${id}/content/bab-2.1.5-peraturan-tingkat-daerah-terkait-kebudayaan/add`);
          break;
        case "bab-2.1.5-peraturan-tingkat-daerah-terkait-kebudayaan":
          router.push(`/dokumen/${id}/content/bab-2.1.5.1-peraturan-yang-berlaku/add`);
          break;
        case "bab-2.1.5.1-peraturan-yang-berlaku":
          router.push(`/dokumen/${id}/content/bab-2.1.5.2-peraturan-yang-pernah-ada-dan-sudah-tidak-berlaku/add`);
          break;
        case "bab-2.1.5.2-peraturan-yang-pernah-ada-dan-sudah-tidak-berlaku":
          router.push(`/dokumen/${id}/content/bab-2.2-ringkasan-proses-penyusunan-ppkd/add`);
          break;
        case "bab-2.2-ringkasan-proses-penyusunan-ppkd":
          router.push(`/dokumen/${id}/content/bab-2.2.1-tim-penyusun/add`);
          break;
        case "bab-2.2.1-tim-penyusun":
          router.push(`/dokumen/${id}/content/bab-2.2.2-proses-pendataan/add`);
          break;
        case "bab-2.2.2-proses-pendataan":
          router.push(`/dokumen/${id}/content/bab-2.2.3-proses-penyusunan-masalah-dan-rekomendasi/add`);
          break;
        case "bab-2.2.3-proses-penyusunan-masalah-dan-rekomendasi":
          router.push(`/dokumen/${id}/content/bab-2.2.4-catatan-evaluasi/add`);
          break;
        case "bab-2.2.4-catatan-evaluasi":
          router.push(`/dokumen/${id}/content/bab-3-lembaga-pendidikan-bidang-kebudayaan/add`);
          break;
        case "bab-3-lembaga-pendidikan-bidang-kebudayaan":
          router.push(`/dokumen/${id}/content/bab-3.1-lembaga-pendidikan-menengah-bidang-kebudayaan/add`);
          break;
        case "bab-3.1-lembaga-pendidikan-menengah-bidang-kebudayaan":
          router.push(`/dokumen/${id}/content/bab-3.2-lembaga-pendidikan-tinggi-bidang-kebudayaan/add`);
          break;
        case "bab-3.2-lembaga-pendidikan-tinggi-bidang-kebudayaan":
          router.push(`/dokumen/${id}/content/bab-4-data-objek-pemajuan-kebudayaan/add`);
          break;
        case "bab-4-data-objek-pemajuan-kebudayaan":
          router.push(`/dokumen/${id}/content/bab-4.1-manuskrip/add`);
          break;
        case "bab-4.1-manuskrip":
          router.push(`/dokumen/${id}/content/bab-4.2-tradisi-lisan/add`);
          break;
        case "bab-4.2-tradisi-lisan":
          router.push(`/dokumen/${id}/content/bab-4.3-adat-istiadat/add`);
          break;
        case "bab-4.3-adat-istiadat":
          router.push(`/dokumen/${id}/content/bab-4.4-ritus/add`);
          break;
        case "bab-4.4-ritus":
          router.push(`/dokumen/${id}/content/bab-4.5-pengetahuan-tradisional/add`);
          break;
        case "bab-4.5-pengetahuan-tradisional":
          router.push(`/dokumen/${id}/content/bab-4.6-teknologi-tradisional/add`);
          break;
        case "bab-4.6-teknologi-tradisional":
          router.push(`/dokumen/${id}/content/bab-4.7-seni/add`);
          break;
        case "bab-4.7-seni":
          router.push(`/dokumen/${id}/content/bab-4.8-bahasa/add`);
          break;
        case "bab-4.8-bahasa":
          router.push(`/dokumen/${id}/content/bab-4.9-permainan-rakyat/add`);
          break;
        case "bab-4.9-permainan-rakyat":
          router.push(`/dokumen/${id}/content/bab-4.10-olahraga-tradisional/add`);
          break;
        case "bab-4.10-olahraga-tradisional":
          router.push(`/dokumen/${id}/content/bab-4.11-cagar-budaya/add`);
          break;
        case "bab-4.11-cagar-budaya":
          router.push(`/dokumen/${id}/content/bab-5-data-sumber-daya-manusia-kebudayaan-dan-lembaga-kebudayaan/add`);
          break;
        case "bab-5-data-sumber-daya-manusia-kebudayaan-dan-lembaga-kebudayaan":
          router.push(`/dokumen/${id}/content/bab-5.1-manuskrip/add`);
          break;
        case "bab-5.1-manuskrip":
          router.push(`/dokumen/${id}/content/bab-5.2-tradisi-lisan/add`);
          break;
        case "bab-5.2-tradisi-lisan":
          router.push(`/dokumen/${id}/content/bab-5.3-adat-istiadat/add`);
          break;
        case "bab-5.3-adat-istiadat":
          router.push(`/dokumen/${id}/content/bab-5.4-ritus/add`);
          break;
        case "bab-5.4-ritus":
          router.push(`/dokumen/${id}/content/bab-5.5-pengetahuan-tradisional/add`);
          break;
        case "bab-5.5-pengetahuan-tradisional":
          router.push(`/dokumen/${id}/content/bab-5.6-teknologi-tradisional/add`);
          break;
        case "bab-5.6-teknologi-tradisional":
          router.push(`/dokumen/${id}/content/bab-5.7-seni/add`);
          break;
        case "bab-5.7-seni":
          router.push(`/dokumen/${id}/content/bab-5.8-bahasa/add`);
          break;
        case "bab-5.8-bahasa":
          router.push(`/dokumen/${id}/content/bab-5.9-permainan-rakyat/add`);
          break;
        case "bab-5.9-permainan-rakyat":
          router.push(`/dokumen/${id}/content/bab-5.10-olahraga-tradisional/add`);
          break;
        case "bab-5.10-olahraga-tradisional":
          router.push(`/dokumen/${id}/content/bab-5.11-cagar-budaya/add`);
          break;
        case "bab-5.11-cagar-budaya":
          router.push(`/dokumen/${id}/content/bab-6-data-sarana-dan-prasarana-kebudayaan/add`);
          break;
        case "bab-6-data-sarana-dan-prasarana-kebudayaan":
          router.push(`/dokumen/${id}/content/bab-6.1-manuskrip/add`);
          break;
        case "bab-6.1-manuskrip":
          router.push(`/dokumen/${id}/content/bab-6.2-tradisi-lisan/add`);
          break;
        case "bab-6.2-tradisi-lisan":
          router.push(`/dokumen/${id}/content/bab-6.3-adat-istiadat/add`);
          break;
        case "bab-6.3-adat-istiadat":
          router.push(`/dokumen/${id}/content/bab-6.4-ritus/add`);
          break;
        case "bab-6.4-ritus":
          router.push(`/dokumen/${id}/content/bab-6.5-pengetahuan-tradisional/add`);
          break;
        case "bab-6.5-pengetahuan-tradisional":
          router.push(`/dokumen/${id}/content/bab-6.6-teknologi-tradisional/add`);
          break;
        case "bab-6.6-teknologi-tradisional":
          router.push(`/dokumen/${id}/content/bab-6.7-seni/add`);
          break;
        case "bab-6.7-seni":
          router.push(`/dokumen/${id}/content/bab-6.8-bahasa/add`);
          break;
        case "bab-6.8-bahasa":
          router.push(`/dokumen/${id}/content/bab-6.9-permainan-rakyat/add`);
          break;
        case "bab-6.9-permainan-rakyat":
          router.push(`/dokumen/${id}/content/bab-6.10-olahraga-tradisional/add`);
          break;
        case "bab-6.10-olahraga-tradisional":
          router.push(`/dokumen/${id}/content/bab-6.11-cagar-budaya/add`);
          break;
        case "bab-6.11-cagar-budaya":
          router.push(`/dokumen/${id}/content/bab-7-permasalahan-dan-rekomendasi/add`);
          break;
        case "bab-7-permasalahan-dan-rekomendasi":
          router.push(`/dokumen/${id}/content/bab-7.1-permasalahan-dan-rekomendasi/add`);
          break;
        case "bab-7.1-permasalahan-dan-rekomendasi":
          router.push(`/dokumen/${id}/content/bab-7.1.1-permasalahan-dan-rekomendasi-manuskrip/add`);
          break;
        case "bab-7.1.1-permasalahan-dan-rekomendasi-manuskrip":
          router.push(`/dokumen/${id}/content/bab-7.1.2-permasalahan-dan-rekomendasi-tradisi-lisan/add`);
          break;
        case "bab-7.1.2-permasalahan-dan-rekomendasi-tradisi-lisan":
          router.push(`/dokumen/${id}/content/bab-7.1.3-permasalahan-dan-rekomendasi-adat-istiadat/add`);
          break;
        case "bab-7.1.3-permasalahan-dan-rekomendasi-adat-istiadat":
          router.push(`/dokumen/${id}/content/bab-7.1.4-permasalahan-dan-rekomendasi-ritus/add`);
          break;
        case "bab-7.1.4-permasalahan-dan-rekomendasi-ritus":
          router.push(`/dokumen/${id}/content/bab-7.1.5-permasalahan-dan-rekomendasi-pengetahuan-tradisional/add`);
          break;
        case "bab-7.1.5-permasalahan-dan-rekomendasi-pengetahuan-tradisional":
          router.push(`/dokumen/${id}/content/bab-7.1.6-permasalahan-dan-rekomendasi-teknologi-tradisional/add`);
          break;
        case "bab-7.1.6-permasalahan-dan-rekomendasi-teknologi-tradisional":
          router.push(`/dokumen/${id}/content/bab-7.1.7-permasalahan-dan-rekomendasi-seni/add`);
          break;
        case "bab-7.1.7-permasalahan-dan-rekomendasi-seni":
          router.push(`/dokumen/${id}/content/bab-7.1.8-permasalahan-dan-rekomendasi-bahasa/add`);
          break;
        case "bab-7.1.8-permasalahan-dan-rekomendasi-bahasa":
          router.push(`/dokumen/${id}/content/bab-7.1.9-permasalahan-dan-rekomendasi-permainan-rakyat/add`);
          break;
        case "bab-7.1.9-permasalahan-dan-rekomendasi-permainan-rakyat":
          router.push(`/dokumen/${id}/content/bab-7.1.10-permasalahan-dan-rekomendasi-olahraga-tradisional/add`);
          break;
        case "bab-7.1.10-permasalahan-dan-rekomendasi-olahraga-tradisional":
          router.push(`/dokumen/${id}/content/bab-7.1.11-permasalahan-dan-rekomendasi-cagar-budaya/add`);
          break;
        case "bab-7.1.11-permasalahan-dan-rekomendasi-cagar-budaya":
          router.push(`/dokumen/${id}/content/bab-7.2-upaya/add`);
          break;
        case "bab-7.2-upaya":
          router.push(`/dokumen/${id}/content/bab-7.3-permasalahan-umum-dan-rekomendasi-umum/add`);
          break;
        case "bab-7.3-permasalahan-umum-dan-rekomendasi-umum":
          router.push(`/dokumen/${id}/content/bab-8-lampiran/add`);
          break;
        case "bab-8-lampiran":
          router.push(`/dokumen/${id}/content/bab-8.1-surat-keputusan-kepala-daerah-tim-penyusun-ppkd/add`);
          break;
        case "bab-8.1-surat-keputusan-kepala-daerah-tim-penyusun-ppkd":
          router.push(
            `/dokumen/${id}/content/bab-8.2-surat-keputusan-tim-penyusun-ppkd-tentang-pemilihan-koordinator/add`
          );
          break;
        case "bab-8.2-surat-keputusan-tim-penyusun-ppkd-tentang-pemilihan-koordinator":
          router.push(`/dokumen/${id}/content/bab-8.3-notulensi-forum-terbuka/add`);
          break;
        case "bab-8.3-notulensi-forum-terbuka":
          router.push(`/dokumen/${id}/content/bab-8.4-notulensi-forum-diskusi-terpumpun/add`);
          break;
        case "bab-8.4-notulensi-forum-diskusi-terpumpun":
          router.push(`/dokumen/${id}/content/bab-8.5-dokumentasi-foto-forum-terbuka/add`);
          break;
        case "bab-8.5-dokumentasi-foto-forum-terbuka":
          router.push(`/dokumen/${id}/content/bab-8.6-dokumentasi-foto-forum-diskusi-terpumpun/add`);
          break;
        case "bab-8.6-dokumentasi-foto-forum-diskusi-terpumpun":
          router.push(`/dokumen/${id}/content/bab-8.7-peraturan-peraturan-yang-berkaitan-dengan-kebudayaan/add`);
          break;
        case "bab-8.7-peraturan-peraturan-yang-berkaitan-dengan-kebudayaan":
          router.push(`/dokumen`);
          break;

        default:
          break;
      }
    },
  },
});
