import {
  defineStore
} from 'pinia';
import {
  fetchWrapper
} from '@/helpers';
import apiStore from './api.store';
import { useAuthStore } from './auth.store';

export const useRegionStore = defineStore({
  id: 'region',
  state: () => ({
      provinces: {},
      regencies: {},
      allSearch: [],
      selectedProvince: null,
      selectedRegency: null,
  }),
  actions: {
        async getRegencies() {
          try {
              this.regencies = { loading: true };
              this.selectedRegency = null;
              const options = {
                  isProvince: true,
                  url: apiStore.region.regencies,
                  params: {
                    page: 1,
                    limit: 1000,
                    order: 'name',
                    sort: 'asc',
                  }
              };
              const getRegencies = await fetchWrapper.get(options);
              this.regencies = getRegencies.data;
          } catch (error) {
              this.regencies = { error };
          }
      },
      async getProvince() {
          try {
              const authStore = useAuthStore();
              this.provinces = { loading: true };
              this.regencies = {};
              this.selectedProvince = null;
              this.selectedRegency = null;
              const options = {
                  isProvince: true,
                  url: apiStore.region.provinces,
                  params: {
                    page: 1,
                    limit: 40,
                    order: 'name',
                    sort: 'asc',
                  }
              };
              const getProvinceResult = await fetchWrapper.get(options);
              this.provinces = getProvinceResult.data;
              if (authStore.user.provinceCode) {
                this.provinces = this.provinces.filter((v) => v.code === authStore.user.provinceCode);
                if (this.provinces.length > 0) await this.filterRegencies(authStore.user.provinceCode);
                this.selectedProvince = authStore.user.provinceCode;
              } 
          } catch (error) {
              this.provinces = { error };
          }
      },
      async filterRegencies(code) {
        try {
          this.regencies = { loading: true };
          this.regencies = {};
          const newUrl = apiStore.region.regenciesProvince.replace(":code", `${code}`);
          const options = {
              isProvince: true,
              url: newUrl,
              params: {
                page: 1,
                limit: 40,
                order: 'name',
                sort: 'asc',
              }
          };
          const getRegencies = await fetchWrapper.get(options);
          this.regencies = getRegencies.data;
          this.selectedProvince = code;
      } catch (error) {
          this.regencies = { error };
      }
    },

    async searchRegenciesAndProfile(search = null) {
      const authStore = useAuthStore();
      const user = authStore.user;
      const getData = [];
      if (search == '' || search == null) { 
        this.allSearch = [];
        return;
      }
      const optionProvince = {
        url: apiStore.region.provinces,
        params: {
          page: 1,
          limit: 5,
          order: 'name',
          sort: 'asc',
          search
        }
      };

      const optionRegency = {
        url: apiStore.region.regencies,
        params: {
          page: 1,
          limit: 5,
          order: 'name',
          sort: 'asc',
          search
        }
      };
      if(!user.provinceCode) getData.push(fetchWrapper.get(optionProvince));
      if(user.provinceCode) optionRegency.params.provinceCode = user.provinceCode;
      getData.push(fetchWrapper.get(optionRegency));
      const dataSearchAll = await Promise.all(getData);
      const mergeData = [];
      dataSearchAll.forEach(v => v.data.forEach(w => mergeData.push({code: w.code, name: w.name})));
      this.allSearch = mergeData;
      // console.log(mergeData);
    }
  }
});