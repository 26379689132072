import { defineStore } from "pinia";
import { fetchWrapper } from "@/helpers";
import apiStore from "./api.store";

export const useDapobudStore = defineStore({
  id: "dapobud",
  state: () => ({
    loadingAll: false,
    selectedCategory: {},
    selectedSubCategory: {},
    collections: [],
    aggregations: [],
    allAggregations: [],
    selectedCollectionMaster: {},
    datas: [],
    datasLK: [],
    datasTB: [],
    totalDataTB: 0,
    totalDataLK: 0,
    totalAllDataTB: 0,
    totalAllDataLK: 0,
    totalData: 0,
    totalAllData: 0,
    categories: [],
    subCategories: [],
    categoryIds: [],
    currentSearchRegion: {},
    currentRegionCode: null,
  }),
  getters: {
    firstCollection: (state) => state.collections[0],
  },
  actions: {
    async getCategoryByAbbr(categoryAbbr) {
      const newUrl = apiStore.dapobud.categoryByAbbr.replace(":dapobudCategoryAbbr", `${categoryAbbr}`);
      const options = {
        url: newUrl,
      };
      this.selectedCategory = { loading: true };
      try {
        const getCategoryResult = await fetchWrapper.get(options);
        this.selectedCategory = getCategoryResult.data;
      } catch (error) {
        this.selectedCategory = { error };
      }
    },

    async getSubCategoryByName(subCategoryName) {
      const newUrl = apiStore.dapobud.subCategoryByName.replace(":subCategoryName", `${subCategoryName}`);
      const options = {
        url: newUrl,
      };
      this.selectedSubCategory = { loading: true };

      try {
        const getSubCategoryResult = await fetchWrapper.get(options);
        this.selectedSubCategory = getSubCategoryResult.data;
      } catch (error) {
        this.selectedSubCategory = { error };
      }
    },

    async getCollectionsByCategory(categoryId) {
      const options = {
        url: apiStore.dapobud.collections,
        params: {
          categoryId,
        },
      };
      this.collections = { loading: true };
      try {
        const getCollectionsResult = await fetchWrapper.get(options);
        this.collections = getCollectionsResult.data;
      } catch (error) {
        this.collections = { error };
      }
    },

    async getCollectionsBySubCategory(subcategoryId) {
      const options = {
        url: apiStore.dapobud.collections,
        params: {
          subcategoryId,
        },
      };
      this.collections = { loading: true };
      try {
        const getCollectionsResult = await fetchWrapper.get(options);
        getCollectionsResult.data.map((v) => {
          if (v.identifier == null) v.identifier = v.name.replaceAll(" ", "-");
        });
        this.collections = getCollectionsResult.data;
      } catch (error) {
        this.collections = { error };
      }
    },

    async getAggregationsByCollection(collectionId) {
      this.aggregations = { loading: true };
      this.selectedCollectionMaster = collectionId;
      const options = {
        url: apiStore.dapobud.aggregationsByCollection.replace(":dapobudCollectionId", collectionId),
      };
      try {
        const getAggResult = await fetchWrapper.get(options);
        this.aggregations = getAggResult.data;
      } catch (error) {
        this.aggregations = { error };
      }
    },

    async getDatasByCollection(search = null, page = 1, limit = 10, order = ["createdAt", "desc"], option) {
      this.datas = { loading: true };
      console.log(option);
      const options = {
        url: apiStore.dapobud.dataByCollection.replace(":dapobudCollectionId", option.selectedCollectionMaster),
        params: {
          order: order[0],
          sort: order[1],
          search: search || null,
          page,
          limit,
        },
      };
      try {
        const getDatas = await fetchWrapper.get(options);
        this.datas = getDatas.data.map((v) => { 
          try {
            if(v.attributes.komponen_pelaku) v.attributes.komponen_pelaku = JSON.parse(v.attributes.komponen_pelaku).join(",");
          } catch (err) {
            v.attributes.komponen_pelaku = v.attributes.komponen_pelaku;
          }
          try {
            if(v.attributes.perlengkapan_yang_digunakan) v.attributes.perlengkapan_yang_digunakan = JSON.parse(v.attributes.perlengkapan_yang_digunakan).join(",");
          } catch (err) {
            v.attributes.perlengkapan_yang_digunakan = v.attributes.perlengkapan_yang_digunakan;
          }
          try {
            if(v.attributes.mantra_yang_digunakan) v.attributes.mantra_yang_digunakan = JSON.parse(v.attributes.mantra_yang_digunakan).join(",");
          } catch (err) {
            v.attributes.mantra_yang_digunakan = v.attributes.mantra_yang_digunakan;
          }
          if(v.attributes.masih_dilaksanan === true) v.attributes.masih_dilaksanan = 'Ya'; 
          if(v.attributes.masih_dilaksanan === false) v.attributes.masih_dilaksanan = 'Tidak'; 
          return v
        });
        this.totalData = getDatas.totalData;
        this.totalAllData = getDatas.totalAllData;
      } catch (error) {
        // console.log(error);
        this.datas = { error };
      }
    },

    async getCategoriesCustom() {
      const options = {
        url: apiStore.dapobud.categories,
      };
      this.categories = { loading: true };
      try {
        this.categories = [
          {
            name: "Objek Pemajuan",
            abbr: "OP",
            id: 1,
          },
          {
            name: "Sumber Daya Manusia dan Lembaga",
            abbr: "LK,TB",
            id: 2,
          },
          {
            name: "Sarana & Prasarana",
            abbr: "SP",
            id: 3,
          },
        ];
        // console.log(this.categories);
      } catch (error) {
        this.categories = { error };
      }
    },

    async getSubCategoriesById(category) {
      this.loadingAll = true;
      this.subCategories = { loading: true };
      this.aggregations = { loading: true };
      const options = {
        url: apiStore.dapobud.subCategories.replace(":dapobudCategoryId", category.value.id),
      };
      try {
        const aggData = [
          {
            BgGrafikJumlahOPK: [
              "#1D6CFF",
              "#57D886",
              "#EF4444",
              "#96CC22",
              "#FFB21D",
              "#8E1DFF",
              "#FF1DC0",
              "#1DD6FF",
              "#FF7C1D",
              "#A98017",
            ],
            attribute: "",
            label: "Grafik Jumlah OPK",
            data: [],
          },
        ];
        const getCategoryResult = await fetchWrapper.get(options);
        this.subCategories = getCategoryResult.data;
        getCategoryResult.data.forEach((v, i) =>
          aggData[0].data.push({ label: v.name.replace("_", " "), count: 0, id: v.name })
        );
        this.aggregations = aggData;
      } catch (error) {
        this.subCategories = { error };
        this.loadingAll = false;
      }
    },

    async getAggregationsBySubCategories() {
      this.loadingAll = true;
      let fetchAll = [];
      let subCategories = [];
      this.subCategories.forEach((v) => {
        const options = {
          url: apiStore.dapobud.aggregationsBySubCategory.replace(":dapobudSubCategoryId", v.id),
        };
        subCategories.push(v.name);
        fetchAll.push(fetchWrapper.get(options));
      });

      try {
        const sum = function (items, prop) {
          return items.reduce(function (a, b) {
            return parseInt(a) + parseInt(b[prop]);
          }, 0);
        };
        const getAggSubCategoryResult = await Promise.all(fetchAll);
        for (let i = 0; i < getAggSubCategoryResult.length; i++) {
          this.aggregations[0].data[i].count = sum(getAggSubCategoryResult[i].data[0].data, "count");
        }
        this.loadingAll = false;
      } catch (error) {
        this.loadingAll = false;
        this.aggregations = { error };
      }
    },

    async getSubCategoryAgg() {
      this.aggregations = { loading: true };
      this.loadingAll = true;
      let provinceCode = null;
      let regencyCode = null;
      if (this.currentRegionCode != null) {
        let arrRegion = this.currentRegionCode.split(".");
        provinceCode = arrRegion.length == 1 ? this.currentRegionCode : null;
        regencyCode = arrRegion.length == 2 ? this.currentRegionCode : null;
      }
      try {
        const options = {
          url: apiStore.dashboard.subCategoryAgg,
          params: {},
        };
        if (regencyCode) options.params.regencyCode = regencyCode;
        if (provinceCode) options.params.provinceCode = provinceCode;

        const getAggSubCategoryResult = await fetchWrapper.get(options);
        const aggData = [
          {
            BgGrafikJumlahOPK: [
              "#1D6CFF",
              "#57D886",
              "#EF4444",
              "#96CC22",
              "#FFB21D",
              "#8E1DFF",
              "#FF1DC0",
              "#1DD6FF",
              "#FF7C1D",
              "#A98017",
            ],
            attribute: "",
            label: "Grafik Jumlah OPK",
            data: [],
          },
        ];
        getAggSubCategoryResult.data.forEach((v, i) =>
          aggData[0].data.push({
            label: v.subcategory.name.replace("_", " "),
            count: v.countDatas,
            id: v.subcategory.name,
          })
        );
        this.aggregations = aggData;
        this.loadingAll = false;
      } catch (error) {
        this.loadingAll = false;
        this.aggregations = { error };
      }
    },

    async getCategoryAgg(categoryAbbrs) {
      this.categoryIds = [];
      this.aggregations = { loading: true };
      this.loadingAll = true;
      let provinceCode = null;
      let regencyCode = null;
      try {
        const options = {
          url: apiStore.dashboard.categoryAgg,
          params: {
            categoryAbbrs,
          },
        };
        if (this.currentRegionCode != null) {
          let arrRegion = this.currentRegionCode.split(".");
          provinceCode = arrRegion.length == 1 ? this.currentRegionCode : null;
          regencyCode = arrRegion.length == 2 ? this.currentRegionCode : null;
        }
        if (regencyCode) options.params.regencyCode = regencyCode;
        if (provinceCode) options.params.provinceCode = provinceCode;
        // console.log(options);
        const getAggSubCategoryResult = await fetchWrapper.get(options);
        const aggData = [
          {
            BgGrafikJumlahOPK: ["#1D6CFF", "#EF4444"],
            attribute: "",
            label:
              categoryAbbrs === "SP"
                ? "Grafik Jumlah Sarana dan Prasarana"
                : "Grafik Jumlah Sumber Daya Manusia dan Lembaga",
            data: [],
          },
        ];
        const categoryIds = [];
        // console.log(categoryAbbrs);
        await getAggSubCategoryResult.data.forEach((v, i) => {
          if (v.category && v.category.name == "Tenaga Budaya") v.category.name = "Sumber Daya Manusia";
          if (categoryAbbrs == "SP") {
            aggData[0].data.push({ label: v.status_kepemilikan.replace("-", " "), count: v.count, id: i });
          } else {
            categoryIds.push({ id: v.category.id, name: v.category.name });
            aggData[0].data.push({
              label: v.category.name.replace("_", " "),
              count: v.countDatas,
              id: v.category.name,
            });
          }
        });
        this.categoryIds = categoryIds;
        this.aggregations = aggData;
        this.loadingAll = false;
      } catch (error) {
        this.loadingAll = false;
        this.aggregations = { error };
      }
    },

    async getDatas(search = null, page = 1, limit = 10, order = ["createdAt", "desc"], option) {
      this.datas = { loading: true };
      let categoryId = null;
      let provinceCode = null;
      let regencyCode = null;
      let ownershipStatus = null;
      if (option.LK === true) categoryId = this.categoryIds[1].id;
      if (option.TB === true) categoryId = this.categoryIds[0].id;
      if (option.SP === true) {
        await this.getCategoryByAbbr("SP");
        categoryId = this.selectedCategory.id || null;
        ownershipStatus = option.sarPasCategory;
      }
      const options = {
        url: apiStore.dapobud.datas,
        params: {
          order: order[0],
          sort: order[1],
          search: search || null,
          page,
          limit,
          categoryId,
          ownershipStatus,
        },
      };
      if (this.currentRegionCode != null) {
        let arrRegion = this.currentRegionCode.split(".");
        // console.log(arrRegion.length);
        provinceCode = arrRegion.length == 1 ? this.currentRegionCode : null;
        regencyCode = arrRegion.length == 2 ? this.currentRegionCode : null;
      }
      if (regencyCode) options.params.regencyCode = regencyCode;
      if (provinceCode) options.params.provinceCode = provinceCode;

      try {
        const getDatas = await fetchWrapper.get(options);
        this.datas = getDatas.data;
        this.totalData = getDatas.totalData;
        this.totalAllData = getDatas.totalAllData;
      } catch (error) {
        this.datas = { error };
      }
    },
  },
});
