import {
  defineStore
} from 'pinia';
import {
  fetchWrapper
} from '@/helpers';
import apiStore from './api.store';

export const useReligionStore = defineStore({
  id: 'religion',
  state: () => ({
    religions: {},
    selectedReligion: null,
  }),
  actions: {
    async getReligions() {
      try {
        this.selectedReligion = null;
        this.religions = { loading: true };
        const options = {
          url: apiStore.statusAttribute.religions,
        };
        const getReligionsResult = await fetchWrapper.get(options);
        this.religions = getReligionsResult.data;
      } catch (error) {
        this.religions = { error };
      }
    }
  }
});