import { Layout, Login } from '@/views/auth';

export default {
    path: '/auth',
    component: Layout,
    children: [
        { path: '', redirect: 'login' },
        { path: 'login', component: Login },
    ]
};
