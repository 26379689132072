<script setup>
import { useAuthStore } from '@/stores';
import { router } from '@/router';

// redirect home if already logged in
const authStore = useAuthStore();
if (authStore.user) {
    router.push('/');
}
</script>

<template>
    <div class="container mt-5">
        <div class="row mt-5">
            <div class="col-sm-6 offset-sm-3 mt-5">
                <router-view />
            </div>
        </div>
    </div>
</template>
