import { useAuthStore } from "@/stores";
import axios from "axios";
import { router } from "@/router";

const setInstance = (options = null) => {
  const url = process.env.VUE_APP_API_URL;
  const baseURL = url;
  const setup = {
    baseURL,
    // timeout: 10000,
  };
  return axios.create(setup);
};

async function authHeader(isMultipart = false) {
  let token = window.$cookies.get("ppkd-at");
  const authStore = useAuthStore();
  if (!token && authStore.user) {
    const refreshToken = await authStore.checkAuth();
    if (!refreshToken) router.push("/");
    token = window.$cookies.get("ppkd-at");
    let header = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };
    if (isMultipart) {
      header = {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
      };
    }

    return header;
  }
  if (token) {
    let header = {
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    };
    if (isMultipart) {
      header = {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data",
      };
    }
    return header;
  }
  return {
    "Access-Control-Allow-Origin": "*",
  };
}

function handleResponse(response) {
  if (response.data) return response.data;
  return null;
}

async function sleep(ms = 5000) {
  // console.log('Kindly remember to remove `sleep`');
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const fetchWrapper = {
  get: async (options) => {
    const requestConfig = {
      url: options.url,
      method: "get",
      params: options.params,
      headers: await authHeader(),
      responseType: options?.responseType
    };
    return setInstance().request(requestConfig).then(handleResponse);
  },
  post: async (options) => {
    const requestConfig = {
      url: options.url,
      method: "post",
      headers: options.headers
        ? options.headers
        : await authHeader(options.isMultipart),
      data: options.body,
    };
    return setInstance().request(requestConfig).then(handleResponse);
  },
  patch: async (options) => {
    const requestConfig = {
      url: options.url,
      method: "patch",
      headers: options.headers
        ? options.headers
        : await authHeader(options.isMultipart),
      data: options.body,
    };
    return setInstance().request(requestConfig).then(handleResponse);
  },
  delete: async (options) => {
    const requestConfig = {
      url: options.url,
      method: "delete",
      headers: options.headers ? options.headers : await authHeader(),
    };
    return setInstance().request(requestConfig).then(handleResponse);
  },
};
