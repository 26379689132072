import permission  from "@/stores/permission.store";

export default {
  path: '/lembaga-pendidikan',
  component: () => import("@/views/educations/Layout.vue"),
  children: [
      { path: '', 
        meta: {
          iconActive: 'lembagaPendidikan',
          permission: permission.education.read,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Lembaga Pendidikan',
                }
            ]
        },
        component: () => import("@/views/educations/List.vue") 
      },
      { path: 'add', 
        meta: {
          iconActive: 'lembagaPendidikan',
          permission: permission.education.create,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Lembaga Pendidikan',
                    link: '/lembaga-pendidikan',
                },
                {
                  name: 'Tambah Lembaga Pendidikan',
                },
            ]
        },
        component: () => import("@/views/educations/Form.vue") 
      },
      { path: 'edit/:id', 
        meta: {
          iconActive: 'lembagaPendidikan',
          permission: permission.education.update,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Lembaga Pendidikan',
                    link: '/lembaga-pendidikan'
                },
                {
                  name: 'Ubah Lembaga Pendidikan',
              },
            ]
        },
        component: () => import("@/views/educations/Form.vue") 
      },
  ]
};
