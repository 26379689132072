<script setup>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import svgIcon from "@/assets/svg-icon";

import { useAuthStore } from "@/stores";

const schema = Yup.object().shape({
  username: Yup.string().required("Username wajib diisi"),
  password: Yup.string().required("Password wajib diisi"),
  remember: Yup.mixed().optional(),
});

async function onSubmit(values) {
  const authStore = useAuthStore();
  const { username, password, remember } = values;
  await authStore.login(username, password, remember || false);
}
const passwordHandle = () => {
  const x = document.getElementById("password-form");
  if (x.type === "password") {
    x.type = "text";
  } else {
    x.type = "password";
  }
};
</script>

<template>
  <div class="container">
    <div class="card m-3 p-3">
      <div class="card-header text-center">
        <p class="card-title bold">PPKD</p>
        <p class="card-sub-title bold">POKOK PIKIRAN KEBUDAYAAN DAERAH</p>
      </div>
      <hr style="width:90%;margin:10px auto">
      <div class="card-body">
        <h2 class="bold">Log in</h2>
        <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors, isSubmitting }">
          <div class="form-group mt-3">
            <label class="bold">Username <span class="text-danger">*</span></label>
            <Field name="username" type="text" class="form-control" :class="{ 'is-invalid': errors.username }"
              placeholder="username atau email" />
            <div class="invalid-feedback">{{ errors.username }}</div>
          </div>
          <div class="form-group mt-3">
            <label class="bold">Password <span class="text-danger">*</span></label>
            <Field name="password" type="password" id="password-form" class="form-control"
              :class="{ 'is-invalid': errors.password }" placeholder="********" />
            <div class="invalid-feedback">{{ errors.password }}</div>

            <span v-html="svgIcon.eye" class="eye mt-2" @click="passwordHandle"></span>
          </div>
          <div class="form-check">
            <Field name="remember" type="checkbox" :value="true" class="form-check-input" />
            <label class="form-check-label" style="font-size:12px;">Biarkan saya tetap masuk</label>
          </div>
          <br>
          <div class="form-group mt-3">
            <button type="submit" class="btn btn-login btn-primary btn-sm" :disabled="isSubmitting">
              <span v-show="isSubmitting" class="spinner-border spinner-border-sm mr-1"></span>
              Log in
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<style scoped>
label {
  font-size: 14px;
}

.bold {
  font-weight: 700;
}

.card {
  box-shadow: 0px 4px 24px 8px rgba(178, 179, 186, 0.2);
  backdrop-filter: blur(12px);
  border: -0px;
  border-radius: 16px;
}

.card-title {
  font-size: 60px;
  color: #1d6cff;
}

.card-sub-title {
  font-size: 12px;
  color: #1d6cff;
  margin-top: -20px;
}

.card-header {
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(12px);
  border-radius: 16px;
  border: 0px;
}

.card-body {
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(12px);
}

.eye {
  right: 0px;
  margin-right: 20px;
  bottom: 75px;
  display: flex;
  top: 200px;
}

.btn-login {
  float: right;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 16px;
  font-weight: 600;
}
</style>
