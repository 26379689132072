<script setup>
import { useAuthStore } from '@/stores';
const authStore = useAuthStore();
</script>
<template>

<footer v-if="authStore.user" class="footer mt-auto py-3 bg-light">
  <div class="container text-center">
    <span class="text-muted">&copy; PPKD 2022</span>
  </div>
</footer>
</template>
<style scoped>
  footer {
    height: 55px;
    bottom: 0;
    width: 100%;
    display: none;
  }
</style>