import permission  from "@/stores/permission.store";

export default {
  path: '/peraturan-daerah',
  component: () => import("@/views/regulations/Layout.vue"),
  children: [
      { path: '', 
        meta: {
          iconActive: 'peraturanDaerah',
          permission: permission.regulation.read,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Peraturan Daerah',
                }
            ]
        },
        component: () => import("@/views/regulations/List.vue") 
      },
      { path: 'add', 
        meta: {
          iconActive: 'peraturanDaerah',
          permission: permission.regulation.create,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Peraturan Daerah',
                    link: '/peraturan-daerah',
                },
                {
                  name: 'Tambah Peraturan Daerah',
                },
            ]
        },
        component: () => import("@/views/regulations/Form.vue") 
      },
      { path: 'edit/:id', 
        meta: {
          iconActive: 'peraturanDaerah',
          permission: permission.regulation.update,
            breadcrumb: [
                {
                    name: 'Dashboard',
                    link: '/',
                },
                {
                    name: 'Peraturan Daerah',
                    link: '/peraturan-daerah'
                },
                {
                  name: 'Ubah Peraturan Daerah',
              },
            ]
        },
        component: () => import("@/views/regulations/Form.vue") 
      },
  ]
};
