import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '@/stores';
import apiStore from './api.store';

export const useRoleStore = defineStore({
    id: 'role',
    state: () => ({
        roles: {},
        role: {},
        permissionSelected: [],
        totalData: 0,
        totalAllData: 0,
    }),
    actions: {
        async getRoles(search = null, page = 1, limit = 10, order = ['createdAt', 'desc']) {
            this.roles = { loading: true };
            this.role = {},
            this.permissionSelected = [],
            this.totalData = 0;
            this.totalAllData = 0;
            const options = {
                url: apiStore.role.roles,
                params: {
                    order: order[0],
                    sort: order[1],
                    search: search || null,
                    page,
                    limit,
                }
            };
            try {
                const getRolesResult = await fetchWrapper.get(options);   
                this.totalData = getRolesResult.totalData;
                this.totalAllData = getRolesResult.totalAllData;
                this.roles = getRolesResult.data;   

                this.roles.forEach((v, i) => {
                    let hasProvince = false;
                    let hasRegency = false;
                    if(v.slug.search("provinsi") > -1) hasProvince = true;
                    if(v.slug.search("kabupaten") > -1)  hasRegency = true;
                    this.roles[i].hasProvince = hasProvince;
                    this.roles[i].hasRegency = hasRegency;
                })
            } catch (error) {
                this.roles = { error };
            }
        },
        async getRole(id) {
            this.role = { loading: true };
            const newUrl = apiStore.role.role.replace(":roleId", `${id}`);
            const options = {
                url: newUrl,
            };
            try {
                const getRoleResult = await fetchWrapper.get(options);    
                this.role = getRoleResult.data;
                this.role.permissions.forEach((v) => {
                    this.permissionSelected.push(v.id);
                });
            } catch (error) {
                this.role = { error };
            }
        },
        async createRole(payload) {
            const alertStore = useAlertStore();
            const newPayload = {
                name: payload.name,
                desc: payload.desc,
                permissionIds: payload.permissions
            }
            delete payload.permission;
            const options = {
                url: apiStore.role.roleCreate,
                body: newPayload
            };
            try {
                await fetchWrapper.post(options);
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async updateRole(id, payload) {
            // console.log(payload);
            const newPayload = {
                name: payload.name,
                desc: payload.desc,
                permissionIds: payload.permissions
            }
            const alertStore = useAlertStore();
            const newUrl = apiStore.role.rolePatch.replace(":roleId", `${id}`);
            const options = {
                url: newUrl,
                body: newPayload
            };
            try {
                await fetchWrapper.patch(options); 
                alertStore.success();  
            } catch (error) {
                console.log(`error`, error);
                alertStore.error(error);
                throw error;
            }
        },
        async deleteRole(id, search=null, page=null, limit=null, order = ['createdAt', 'desc']) {
            const index = this.roles.findIndex(x => x.id === id);
            if (index > -1) this.roles[index].isDeleting = true;
            const alertStore = useAlertStore();
            const newUrl = apiStore.role.roleDelete.replace(":roleId", `${id}`);
            const options = {
                url: newUrl,
            };
            try {
                await fetchWrapper.delete(options);
                this.getRoles(search, page, limit, order);
                alertStore.success('Data berhasil dihapus');  
            } catch (error) {
                this.roles[index].isDeleting = false;
                console.log(`error`, error);
                alertStore.error(error);
            }
        },
    }
});
