<script>
import { useAuthStore } from "@/stores";
export default {
  name: "Breadcrumb",
  data() {
    return {
      breadcrumbList: [],
      authStore: {},
    };
  },
  mounted() {
    this.updateList();
    const authStore = useAuthStore();
    this.authStore = authStore;
  },
  watch: {
    $route() {
      this.updateList();
    },
  },
  methods: {
    routeTo(pRouteTo) {
      if (this.breadcrumbList[pRouteTo].link)
        this.$router.push(this.breadcrumbList[pRouteTo].link);
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
};
</script>
<template>
  <div v-if="authStore.user" class="container">
    <nav aria-label="breadcrumb">
      <ul class="breadcrumb">
        <li
          v-for="(breadcrumb, index) in breadcrumbList"
          :key="index"
          @click="routeTo(index)"
          :class="{ linked: !!breadcrumb.link }"
        >
          {{ breadcrumb.name }}
        </li>
      </ul>
    </nav>
  </div>
</template>
<style scoped>
.breadcrumb {
  margin-top: 24px;
  margin-bottom: 32px;
  margin-left: 0px;
}
ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
ul > li {
  display: flex;
  float: left;
  height: 10px;
  width: auto;
  color: grey;
  font-weight: bold;
  font-size: 0.8em;
  cursor: default;
  align-items: center;
}
ul > li:not(:last-child)::after {
  content: "/";
  float: right;
  font-size: 0.8em;
  margin: 0 0.5em;
  color: black;
  cursor: default;
}
.linked {
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 8px;
}
</style>
